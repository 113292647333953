import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type {
	Measurements,
	MeasurementValue,
	SensorMeasurementsNavigatorVariables,
} from "../client/device"

export function useSensorMeasurementsNavigatorQuery<M extends MeasurementValue>(
	variables: SensorMeasurementsNavigatorVariables,
	options?: UseQueryOptions<Measurements<M>>,
): UseQueryResult<Measurements<M>> {
	const client = useClient()
	return useQuery<Measurements<M>>(
		["sensorMeasurementsNavigator", variables],
		async () => client.device.getSensorMeasurementsNavigator(variables),
		options,
	)
}

import format from "date-fns/format"

/** https://django.readthedocs.io/en/3.0.x/ref/settings.html#date-input-formats */
export const DATE_FORMAT = "y-MM-dd"
/** https://django.readthedocs.io/en/3.0.x/ref/settings.html#time-input-formats */
export const TIME_FORMAT = "HH:mm:ss.SSSSSS"
/** https://django.readthedocs.io/en/3.0.x/ref/settings.html#datetime-input-formats */
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export function formatDate(date: Date): string {
	return format(date, DATE_TIME_FORMAT)
}

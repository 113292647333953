import React from "react";
import NumberMeasurementChart, { NumberMeasurementChartProps } from "../NumberMeasurementsChart";

export type HumidityChartProps = NumberMeasurementChartProps;

const HumidityChart: React.FC<HumidityChartProps> = ({
  data,
  unit = "%",
  displayLegends = false,
  ...props
}) => {
  return (
    <NumberMeasurementChart
      data={data}
      unit={unit}
      min={15}
      max={42}
      displayLegends={displayLegends}
      bottomAxisLabel="Páratatalom"
      {...props}
    />
  );
};

export default HumidityChart;

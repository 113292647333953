import { IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Edit } from "@material-ui/icons";
import isNil from "lodash.isnil";
import React from "react";
import styled, { css } from "styled-components";
import { formatDateTime } from "../../util";
import Tooltip from "../Tooltip";

export interface BeeBoxMeasurementsCardProps {
  title: string;
  onEdit?: () => void;
  rows: Array<{ label: string; value: string; tooltipText?: string }>;
  actions?: React.ReactNode;
  date?: Date;
}

const StyledCard = styled(Card)(
  (props) => css`
    background-color: ${props.theme.palette.secondary.main};
  `,
);

const StyledCardActions = styled(CardActions)`
  padding-top: 0;
`;

const StyledTitleContainer = styled(Grid)(
  (props) => css`
    margin-bottom: ${props.theme.spacing(1)}px;
  `,
);

const StyledTitle = styled(Typography)`
  font-weight: bold;
`;

const StyledLabel = styled(Typography)(
  (props) => css`
    color: ${props.theme.palette.text.secondary};
  `,
);

const StyledValue = styled(Typography)(
  () => css`
    font-weight: 600;
  `,
);

const StyledGrid = styled(Grid)(
  (props) => css`
    margin-right: ${props.theme.spacing(1)}px;
  `,
);

function BeeBoxMeasurementsCard({
  title,
  onEdit,
  rows,
  actions,
  date,
}: BeeBoxMeasurementsCardProps): React.ReactElement {
  return (
    <StyledCard>
      <CardContent>
        <StyledTitleContainer container alignItems="center" spacing={1}>
          <Grid item>
            <StyledTitle>{title}</StyledTitle>
            {date && <Typography variant="subtitle2">{formatDateTime(date)}</Typography>}
          </Grid>
          {onEdit && (
            <Grid item>
              <IconButton size="small" onClick={onEdit}>
                <Edit fontSize="inherit" color="primary" />
              </IconButton>
            </Grid>
          )}
        </StyledTitleContainer>

        {rows.map((row) => (
          <Grid key={row.label} container alignItems="center" justify="space-between">
            <Grid item>
              <StyledLabel>{row.label}</StyledLabel>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                {!isNil(row.tooltipText) && (
                  <StyledGrid item>
                    <Tooltip text={row.tooltipText} />
                  </StyledGrid>
                )}
                <Grid item>
                  <StyledValue>{row.value}</StyledValue>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </CardContent>
      {actions != null && <StyledCardActions>{actions}</StyledCardActions>}
    </StyledCard>
  );
}

export default BeeBoxMeasurementsCard;

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { HiveGroup, IdVariable } from "../client"

export function useHiveGroupQuery(
	variables: IdVariable,
	options?: UseQueryOptions<HiveGroup>,
): UseQueryResult<HiveGroup> {
	const client = useClient()
	return useQuery(
		["hiveGroup", variables],
		async () => client.hiveGroup.get(variables),
		options,
	)
}

import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import React from "react";

export interface LogoutIconButtonProps extends IconButtonProps {
  onClick: () => void;
}

const LogoutIconButton: React.FC<LogoutIconButtonProps> = ({ onClick, ...props }) => {
  return (
    <IconButton {...props} aria-label="logout" onClick={onClick}>
      <LogoutIcon />
    </IconButton>
  );
};

export default LogoutIconButton;

import Axios from "axios"
import urlcat from "urlcat"
import { objectToFormData } from "../utils/objectToFormData"
import type {
	EmptyObject,
	GetByIdFunction,
	ListFunction,
	Page,
	ResourceConfig,
	UpdateVariables,
} from "./common"
import { mergeWithDetailedQueryParams } from "./common"
import type { Device, Sensor } from "./device"
import type { Hive } from "./hive"
import type { User } from "./session"

export type HiveInHiveGroup = Omit<Hive, "sensors" | "devices"> & {
	devices: number[]
	sensors: string[]
}

export type DeviceInHiveGroup = Omit<Device, "sensors"> & {
	sensors: string[]
}

/** https://bee-box.io/ng/docs/rest/models.html#hivegroupjson */
export type HiveGroup = {
	id: number
	name: string
	description: string
	geojson: GeoJSON.Feature<GeoJSON.Polygon>
	preferred: false
	labels: []
	owner: User
	hives: HiveInHiveGroup[]
	devices: DeviceInHiveGroup[]
	sensors: Sensor[]
}
export type ListHiveGroups = ListFunction<HiveGroup>

export type GetHiveGroup = GetByIdFunction<HiveGroup>

export type UpdateHiveGroupVariables = UpdateVariables<{
	name: string
}>

export type UpdateHiveGroup = (
	variables: UpdateHiveGroupVariables,
) => Promise<EmptyObject>

export type HiveGroupResource = {
	list: ListHiveGroups
	get: GetHiveGroup
	update: UpdateHiveGroup
}

/**
 * HiveGroup Group
 *
 * https://bee-box.io/ng/docs/rest/index.html#api-HiveGroup-group
 */
export function hiveGroup(defaultConfig: ResourceConfig): HiveGroupResource {
	const axios = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "hive/group/"),
	})

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-HiveGroup-group-list- */
	const list: ListHiveGroups = async (variables) =>
		(
			await axios.get<Page<HiveGroup>>("list/", {
				params: mergeWithDetailedQueryParams(variables ?? {}),
			})
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-HiveGroup-group-(int-HiveGroup_group_id)- */
	const get: GetHiveGroup = async ({ id }) =>
		(
			await axios.get<HiveGroup>(urlcat(":id/", { id }), {
				params: mergeWithDetailedQueryParams({}),
			})
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#post--api-hive-group-(int-hive_group_id)-patch- */
	const update: UpdateHiveGroup = async ({ id, name }) => {
		const { data } = await axios.post<EmptyObject>(
			urlcat(":id/patch/", { id }),
			objectToFormData({ name }),
		)
		return data
	}

	return {
		list,
		get,
		update,
	}
}

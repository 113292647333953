import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { SensorMeasurements24hChangeVariables } from "../client/custom"

// Allows `undefined` variables to support easier rendering logic:
// it is not certain if an entity has a specific sensor,
// therefore the components have to be either conditionally rendered (they can't use hooks),
// or the hooks have to allow for this conditional logic.
// We are going forward with the 2nd choice for now,
// until the sensor mess is dealt with on hardware/backend side.
export function useSensorMeasurements24hChangeQuery(
	variables?: SensorMeasurements24hChangeVariables,
	options?: UseQueryOptions<number | null>,
): UseQueryResult<number | null> {
	const client = useClient()
	return useQuery<number | null>(
		["sensorMeasurements24hChanges", variables],
		async () => {
			// Type guard for variables, `useQuery` won't call the method if `enabled` is `false`
			if (!variables) {
				return null
			}
			return client.custom.getSensorMeasurements24hChange(variables)
		},
		{
			enabled: !!variables,
			...options,
		},
	)
}

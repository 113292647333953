import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BeeBoxLogoIconProps {
  color?: string;
}

const BeeBoxLogoIcon: React.FC<BeeBoxLogoIconProps> = ({ color = "#083050" }) => {
  return (
    <svg width="67" height="48" viewBox="0 0 67 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.21661 46.3104V47.1214C1.21661 47.527 0.946254 47.7973 0.608306 47.7973C0.270359 47.7973 0 47.5946 0 47.1214V32.9952C0 32.5897 0.270359 32.3193 0.608306 32.3193C0.946254 32.3193 1.21661 32.5221 1.21661 32.9952V38.3348C1.75733 37.3885 2.70358 36.7802 4.39332 36.7802C6.21823 36.7802 7.50243 37.7265 8.04315 39.4162C8.31351 40.2949 8.44869 41.106 8.44869 42.3226C8.44869 43.5392 8.31351 44.3503 8.04315 45.2289C7.50243 46.9187 6.21823 47.8649 4.39332 47.8649C2.70358 47.8649 1.75733 47.189 1.21661 46.3104ZM6.96172 44.7558C7.16448 44.0799 7.23208 43.3364 7.23208 42.255C7.23208 41.1736 7.16448 40.4977 6.96172 39.8218C6.55618 38.6052 5.60993 37.9293 4.25814 37.9293C2.90635 37.9293 1.9601 38.5376 1.48697 39.7542C1.2842 40.3625 1.21661 41.2411 1.21661 42.255C1.21661 43.3364 1.2842 44.2151 1.48697 44.8234C1.89251 46.1076 2.90635 46.6483 4.25814 46.6483C5.60993 46.7159 6.55618 45.9724 6.96172 44.7558Z"
        fill={color}
      />
      <path
        d="M11.4902 45.0938C11.2874 44.4179 11.1523 43.6744 11.1523 42.3226C11.1523 40.9708 11.2874 40.2273 11.4902 39.5514C12.0985 37.7941 13.5179 36.7803 15.4104 36.7803C17.3029 36.7803 18.7899 37.7941 19.3982 39.5514C19.601 40.2273 19.7361 41.0384 19.7361 42.1198C19.7361 42.4578 19.5334 42.6606 19.1954 42.6606H12.5716C12.5041 42.6606 12.4365 42.7281 12.4365 42.7957C12.4365 43.6744 12.5041 44.1475 12.6392 44.6207C13.1124 46.04 14.1938 46.7159 15.6808 46.7159C16.8974 46.7159 17.776 46.3104 18.5871 45.4993C18.7223 45.3641 18.8575 45.2966 19.0602 45.2966C19.3982 45.2966 19.601 45.4993 19.601 45.8373C19.601 45.9724 19.5334 46.1076 19.4658 46.2428C18.7223 47.1891 17.4381 47.9325 15.6808 47.9325C13.5179 47.8649 12.0985 46.9187 11.4902 45.0938ZM18.3843 41.5115C18.4519 41.5115 18.5195 41.4439 18.5195 41.3764C18.5195 40.7681 18.4519 40.2949 18.3168 39.8218C17.9112 38.47 16.8298 37.7941 15.478 37.7941C14.0586 37.7941 13.0448 38.47 12.6392 39.8218C12.5041 40.2273 12.4365 40.7005 12.4365 41.3764C12.4365 41.4439 12.5041 41.5115 12.5716 41.5115H18.3843Z"
        fill={color}
      />
      <path
        d="M22.7101 45.0938C22.5073 44.4179 22.3721 43.6744 22.3721 42.3226C22.3721 40.9708 22.5073 40.2273 22.7101 39.5514C23.3184 37.7941 24.7377 36.7803 26.6302 36.7803C28.5228 36.7803 30.0097 37.7941 30.618 39.5514C30.8208 40.2273 30.956 41.0384 30.956 42.1198C30.956 42.4578 30.7532 42.6606 30.4153 42.6606H23.7915C23.7239 42.6606 23.6563 42.7281 23.6563 42.7957C23.6563 43.6744 23.7239 44.1475 23.8591 44.6207C24.3322 46.04 25.4136 46.7159 26.9006 46.7159C28.1172 46.7159 28.9959 46.3104 29.807 45.4993C29.9421 45.3641 30.0773 45.2966 30.2801 45.2966C30.618 45.2966 30.8208 45.4993 30.8208 45.8373C30.8208 45.9724 30.7532 46.1076 30.6856 46.2428C29.9421 47.1891 28.6579 47.9325 26.9006 47.9325C24.7377 47.8649 23.3184 46.9187 22.7101 45.0938ZM29.6042 41.5115C29.6718 41.5115 29.7394 41.4439 29.7394 41.3764C29.7394 40.7681 29.6718 40.2949 29.5366 39.8218C29.1311 38.47 28.0496 37.7941 26.6978 37.7941C25.2785 37.7941 24.2646 38.47 23.8591 39.8218C23.7239 40.2273 23.6563 40.7005 23.6563 41.3764C23.6563 41.4439 23.7239 41.5115 23.7915 41.5115H29.6042Z"
        fill={color}
      />
      <path
        d="M36.8363 46.6483C36.6335 47.1891 36.1604 47.7974 35.2817 47.7974C34.3354 47.7974 33.7271 47.1891 33.7271 46.1752V34.0091C33.7271 32.9953 34.403 32.387 35.2817 32.387C36.1604 32.387 36.8363 32.9953 36.8363 34.0091V37.7941C37.2418 37.1858 38.1881 36.6451 39.6074 36.6451C41.3648 36.6451 42.7841 37.7265 43.3924 39.4163C43.6628 40.2273 43.798 41.106 43.798 42.3226C43.798 43.5392 43.6628 44.4179 43.3924 45.229C42.8517 46.9187 41.4323 48.0001 39.6074 48.0001C38.1881 47.865 37.3094 47.3242 36.8363 46.6483ZM40.4185 44.0124C40.5537 43.6068 40.6213 42.9985 40.6213 42.255C40.6213 41.5115 40.5537 40.9032 40.4185 40.4977C40.1481 39.6866 39.5398 39.2135 38.7288 39.2135C37.9177 39.2135 37.3094 39.619 37.1066 40.4977C36.9714 40.9032 36.9039 41.5115 36.9039 42.255C36.9039 42.9985 36.9714 43.6068 37.1066 44.0124C37.377 44.8234 37.9177 45.2966 38.7288 45.2966C39.5398 45.229 40.1481 44.8234 40.4185 44.0124Z"
        fill={color}
      />
      <path
        d="M45.8933 44.7559C45.6229 43.9448 45.5553 43.269 45.5553 42.1875C45.5553 41.1061 45.6905 40.4302 45.8933 39.6191C46.5692 37.659 48.1913 36.51 50.5569 36.51C52.9226 36.51 54.6123 37.659 55.2206 39.6191C55.491 40.4302 55.5586 41.1061 55.5586 42.1875C55.5586 43.269 55.4234 43.9448 55.2206 44.7559C54.5447 46.716 52.9226 47.865 50.5569 47.865C48.1913 47.865 46.5016 46.7836 45.8933 44.7559ZM52.2467 44.0124C52.3819 43.5393 52.4494 42.931 52.4494 42.1875C52.4494 41.444 52.3819 40.8357 52.2467 40.3626C51.9763 39.6191 51.4356 39.146 50.5569 39.146C49.6783 39.146 49.1376 39.6191 48.8672 40.3626C48.732 40.8357 48.6644 41.444 48.6644 42.1875C48.6644 42.931 48.732 43.5393 48.8672 44.0124C49.1376 44.7559 49.6783 45.229 50.5569 45.229C51.4356 45.229 51.9763 44.8235 52.2467 44.0124Z"
        fill={color}
      />
      <path
        d="M63.7369 47.0538L61.7092 44.3503L59.6815 47.0538C59.3436 47.527 58.8704 47.7297 58.3973 47.7297C57.5862 47.7297 56.9779 47.1214 56.9779 46.3779C56.9779 46.04 57.1131 45.702 57.3835 45.3641L59.9519 42.0522L57.6538 39.0783C57.3835 38.7403 57.2483 38.4024 57.2483 38.0644C57.2483 37.321 57.8566 36.7126 58.6677 36.7126C59.1408 36.7126 59.6139 36.9154 59.9519 37.3885L61.7092 39.6866L63.4665 37.3885C63.8045 36.9154 64.2776 36.7126 64.7507 36.7126C65.5618 36.7126 66.1701 37.321 66.1701 38.0644C66.1701 38.4024 66.0349 38.7403 65.7646 39.0783L63.4665 42.0522L66.0349 45.3641C66.3053 45.702 66.4405 46.04 66.4405 46.3779C66.4405 47.1214 65.8322 47.7297 65.0211 47.7297C64.6155 47.7973 64.1424 47.527 63.7369 47.0538Z"
        fill={color}
      />
      <path
        d="M32.4429 18.8688C33.7271 20.153 35.7548 20.153 37.039 18.8688C38.3232 17.5846 38.3232 15.557 37.039 14.2728C36.2955 13.5293 35.2817 13.1913 34.2679 13.3941C33.7271 12.8534 33.6596 12.0423 33.9975 11.3664C34.2003 11.434 34.4706 11.3664 34.6058 11.1636C34.8762 10.8933 34.8762 10.4201 34.6058 10.1498C34.3355 9.87943 33.8623 9.87943 33.592 10.1498C33.3892 10.3526 33.3216 10.6905 33.4568 10.9609C32.9837 11.7719 33.0513 12.7858 33.592 13.5293C32.7133 13.8672 32.0374 14.5431 31.6995 15.4218C30.956 14.8811 29.9421 14.8135 29.1311 15.2866C28.8607 15.1514 28.5228 15.219 28.32 15.4218C28.0496 15.6921 28.0496 16.1653 28.32 16.4356C28.5903 16.706 29.0635 16.706 29.3338 16.4356C29.469 16.3004 29.6042 16.0301 29.5366 15.8273C30.2125 15.4894 31.0236 15.6245 31.5643 16.0977C31.3615 17.1791 31.6995 18.1929 32.4429 18.8688Z"
        fill={color}
      />
      <path
        d="M42.4462 0.349411C40.824 0.890127 39.5398 2.03915 38.7964 3.52612C38.1205 4.87791 37.9177 7.5139 38.2556 10.623C38.4584 12.3803 38.9315 15.557 39.7426 15.9626C40.5537 16.3681 43.3249 14.8812 44.8794 13.9349C46.434 12.9886 47.7858 11.9072 48.732 10.961C49.4079 10.2851 49.881 9.67676 50.1514 9.13604C50.8949 7.64908 51.0301 5.89175 50.4893 4.33719C49.9486 2.71504 48.7996 1.43084 47.3126 0.687358C45.7581 -0.123716 44.0683 -0.191305 42.4462 0.349411Z"
        fill={color}
      />
      <path
        d="M28.9959 30.6971C31.5643 28.1287 34.5382 22.7891 33.9975 21.7077C33.592 20.8966 30.4829 20.3559 28.6579 20.2207C25.5488 19.8828 22.9128 20.1531 21.561 20.7614C20.0741 21.5049 18.9251 22.7891 18.3843 24.4113C17.8436 26.0334 17.9788 27.7231 18.7223 29.2101C19.4658 30.6971 20.75 31.8461 22.3721 32.3868C23.9943 32.9275 25.684 32.7923 27.171 32.0489C27.7117 31.8461 28.32 31.373 28.9959 30.6971Z"
        fill={color}
      />
      <path
        d="M46.2312 15.6922C43.1221 16.2329 40.2833 17.7199 38.0529 20.0179C35.7549 22.316 34.3355 25.0871 33.7272 28.1962C33.6596 28.6694 33.7272 29.2101 34.0651 29.6156C34.4031 30.0212 34.8086 30.2239 35.3493 30.2915C36.228 30.3591 37.1066 29.7508 37.2418 28.8046C37.6474 26.4389 38.7964 24.2761 40.5537 22.5187C42.2434 20.829 44.4739 19.68 46.8395 19.2068C47.1775 19.1393 47.5154 19.0041 47.7858 18.7337C48.1237 18.3958 48.3265 17.8551 48.3265 17.3143V17.2467C48.2589 16.7736 48.0561 16.3681 47.6506 16.0977C47.2451 15.7598 46.7043 15.6246 46.2312 15.6922Z"
        fill={color}
      />
      <path
        d="M44.2711 29.7509C44.0683 30.2916 44.1359 30.8324 44.4063 31.3055C44.6766 31.7786 45.2174 32.1166 45.7581 32.1166C46.2988 32.1841 46.8395 31.9814 47.1775 31.5758C47.3802 31.3731 47.4478 31.1703 47.583 30.9675C47.8534 30.2916 48.3941 29.7509 49.07 29.4806C49.3403 29.413 49.5431 29.2102 49.6783 29.075C50.0838 28.6695 50.2866 28.1288 50.219 27.6556C50.1514 27.1149 49.881 26.5742 49.4079 26.3039C48.9348 26.0335 48.3265 25.9659 47.8534 26.1687C46.1636 26.8446 44.8794 28.1288 44.2711 29.7509Z"
        fill={color}
      />
      <path
        d="M49.4079 33.7385C50.0838 34.4144 51.0977 34.4144 51.7736 33.7385C52.4495 33.0626 52.4495 32.0488 51.7736 31.3729C51.0977 30.697 50.0838 30.697 49.4079 31.3729C48.7996 31.9812 48.7996 33.0626 49.4079 33.7385Z"
        fill={color}
      />
      <path
        d="M41.8379 23.803C40.4185 25.2223 39.4047 27.0473 38.9315 29.0073C38.7964 29.4805 38.9315 30.0212 39.2019 30.4267C39.4723 30.8323 40.013 31.1026 40.4861 31.1702C41.3648 31.2378 42.1758 30.6295 42.3786 29.8184C42.649 28.5342 43.3249 27.3176 44.2711 26.3714C45.2174 25.4251 46.434 24.7492 47.7182 24.4113C48.0561 24.3437 48.3941 24.1409 48.5968 23.9381C48.9348 23.6002 49.1376 23.0595 49.1376 22.5188C49.07 21.978 48.7996 21.5049 48.3941 21.2346C47.9885 20.8966 47.4478 20.829 46.9747 20.9642C45.0822 21.3697 43.2573 22.3836 41.8379 23.803Z"
        fill={color}
      />
    </svg>
  );
};

export default BeeBoxLogoIcon;

import {
  createMuiTheme,
  StylesProvider,
  Theme,
  ThemeProvider as MUIThemeProvider,
} from "@material-ui/core/styles";
import React, { useMemo } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { theme as muiTheme } from "../../theme";

export interface ThemeProviderProps {
  theme?: Theme;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ theme: themeProp, children }) => {
  const theme = useMemo(() => (themeProp ? createMuiTheme(muiTheme, themeProp) : muiTheme), [
    themeProp,
  ]);
  return (
    <StylesProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
      </MUIThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;

import snakeCase from "lodash.snakecase"
import { formatDate } from "./format"

type Value = string | Date | File

function toFormDataValue(value: Value | null): string | Blob {
	if (value instanceof Date) {
		return formatDate(value)
	}
	if (value === null) {
		return ""
	}
	return value
}

export function objectToFormData(object: {
	[key: string]: Value | Value[] | null | undefined
}): FormData {
	const formData = new FormData()
	Object.entries(object).forEach(([key, value]) => {
		if (value !== undefined) {
			;(Array.isArray(value) ? value : [value]).forEach((v) => {
				formData.append(snakeCase(key), toFormDataValue(v))
			})
		}
	})
	return formData
}

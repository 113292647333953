import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  font-weight: 700;
  font-size: 0.85em;
`;

const MeasurementsCardActionButton: React.FC<ButtonProps> = ({ onClick, children, ...props }) => {
  return (
    <StyledButton onClick={onClick} size="small" {...props}>
      {children}
    </StyledButton>
  );
};

export default MeasurementsCardActionButton;

import React from "react";
import { Line, LineChart, LineProps as LineProp, ResponsiveContainer } from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

export interface ChartDataBaseType {
  value: number | null;
}

// Recharts can have anything in the data field specified
// But be careful, the dataKeys must match in Line, Axes, etc. !!
export type RechartSeries<T extends ChartDataBaseType> = Array<{
  name: string;
  data: T[];
  yAxisId?: "left" | "right";
}>;

export interface RechartsChartProps extends CategoricalChartProps {
  data: RechartSeries<ChartDataBaseType>;
  dot?: boolean;
  biAxial?: boolean;
  LineProps?: LineProp;
}

const colors = {
  0: "#00e673",
  1: "#2f4b7c",
  2: "#996633",
  3: "#ff33cc",
  4: "#00ccff",
  5: "#ff9933",
  6: "#000099",
  7: "#333300",
  8: "#ff6699",
  9: "#00ffff",
  10: "#009999",
  11: "#e6e600",
};

const RechartsChart: React.FC<RechartsChartProps> = ({
  data,
  biAxial = false,
  dot = false,
  children,
  LineProps,
  ...props
}) => {
  return (
    <ResponsiveContainer width="100%">
      <LineChart {...props}>
        {data.map((s, index) => (
          // The two types does not  overlap because the LineProps have SVGPAthElement as ref
          // The component has Line as component reference
          // eslint-disable-next-line
          // @ts-expect-error
          <Line
            dataKey="value"
            // Deafult y axis has id "left"
            yAxisId={biAxial ? s.yAxisId ?? "left" : "left"}
            legendType="square"
            dot={dot}
            type="monotone"
            stroke={Object.values(colors)[index % 12]}
            data={s.data}
            name={s.name}
            key={s.name}
            {...LineProps}
          />
        ))}

        {children}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RechartsChart;

import { Button } from "@material-ui/core";
import isNil from "lodash.isnil";
import React, { useState } from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { RefAreaDomain, useChartZoom } from "../../hooks/useChartZoom";
import { useNumberMeasurementChart } from "../../hooks/useNumberMeasurementChart";
import { formatChartValue } from "../../util";
import RechartsChart, {
  ChartDataBaseType,
  RechartsChartProps,
  RechartSeries,
} from "../RechartsChart";

export interface NumberMeasurementChartProps extends RechartsChartProps {
  data: RechartSeries<NumberMeasurementChartDataType>;
  min?: number;
  max?: number;
  rightAxisMin?: number;
  rightAxisMax?: number;
  unit?: string;
  showMarker?: true;
  displayLegends?: boolean;
  bottomAxisLabel?: string;
  markedData?: NumberMeasurementChartDataType;
  zoomable?: boolean;
}

export interface NumberMeasurementChartDataType extends ChartDataBaseType {
  quantity?: number;
  dateInMs: number;
}

const NumberMeasurementChart: React.FC<NumberMeasurementChartProps> = ({
  data,
  min,
  max,
  rightAxisMax,
  rightAxisMin,
  unit,
  bottomAxisLabel,
  displayLegends = false,
  showMarker = false,
  markedData,
  zoomable = false,
  biAxial,
  ...props
}) => {
  const { domainMin, domainMax, dateValueFormat } = useNumberMeasurementChart({
    data,
    unit,
    min,
    max,
  });

  const [refArea, setRefArea] = useState<RefAreaDomain>({ left: "", right: "" });

  const { zoom, zoomOut, xAxis, yAxis } = useChartZoom(refArea, domainMin, domainMax);

  const zoomProps = zoomable
    ? {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onMouseDown: (e: any) => setRefArea({ ...refArea, left: e.activeLabel as string }),
        onMouseMove: (e: any) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          refArea.left && setRefArea({ ...refArea, right: e.activeLabel as string }),

        onMouseUp: () => {
          zoom();
          setRefArea({ left: "", right: "" });
        },
      }
    : {};

  return (
    <>
      <RechartsChart
        biAxial={biAxial}
        data={data}
        dot
        margin={{
          top: 20,
          right: 20,
          left: 10,
          bottom: 20,
        }}
        {...zoomProps}
        {...props}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          allowDuplicatedCategory={false}
          allowDataOverflow
          dataKey="dateInMs"
          tickFormatter={(value) => dateValueFormat(value)}
          angle={0}
          tickMargin={25}
          fontSize={12}
          domain={[xAxis.left, xAxis.right]}
        >
          <Label position="center">{bottomAxisLabel}</Label>
        </XAxis>

        <YAxis
          yAxisId="left"
          type="number"
          allowDataOverflow
          fontSize={14}
          dataKey="value"
          tickFormatter={(value) => formatChartValue(value, unit)}
          domain={[yAxis.bottom, yAxis.top]}
        />

        {!isNil(biAxial) && biAxial && (
          <YAxis
            yAxisId="right"
            orientation="right"
            type="number"
            mirror
            allowDataOverflow
            fontSize={14}
            dataKey="value"
            tickFormatter={(value) => formatChartValue(value, unit)}
            domain={[rightAxisMin ?? "dataMin", rightAxisMax ?? "dataMax"]}
          />
        )}

        <Tooltip
          labelFormatter={(value) => dateValueFormat(value)}
          formatter={(
            value: number | undefined,
            _: unknown,
            { payload }: { payload: NumberMeasurementChartDataType },
          ) => {
            return !isNil(value) ? formatChartValue(value, unit, payload.quantity) : "";
          }}
        />
        {displayLegends && (
          <Legend
            verticalAlign="bottom"
            height={48}
            wrapperStyle={{
              paddingTop: "10px",
              overflowY: "hidden",
            }}
            formatter={(value) => {
              const val = value as string;
              if (val.length >= 20) {
                return `${val.slice(0, 19)}...`;
              }
              return val;
            }}
          />
        )}

        {showMarker && !isNil(markedData) && (
          <ReferenceLine yAxisId="left" x={markedData.dateInMs} stroke="red" strokeWidth={2} />
        )}

        {zoomable && refArea.left && refArea.right ? (
          <ReferenceArea yAxisId="left" x1={refArea.left} x2={refArea.right} strokeOpacity={0.3} />
        ) : null}
      </RechartsChart>
      {zoomable && <Button onClick={() => zoomOut()}>Zoom out</Button>}
    </>
  );
};

export default NumberMeasurementChart;

import {
  Tooltip as MaterialTooltip,
  TooltipProps as MaterialTooltipProps,
} from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import styled, { css } from "styled-components";

export interface BeeBoxTooltipProps {
  text: string;
  TooltipProps?: Partial<MaterialTooltipProps>;
}

const StyledIcon = styled(InfoOutlined)(
  (props) => css`
    color: ${props.theme.palette.grey[500]};
  `,
);
const Tooltip: React.FC<BeeBoxTooltipProps> = ({ text, TooltipProps }) => {
  return (
    <MaterialTooltip {...TooltipProps} title={text}>
      <StyledIcon fontSize="small" />
    </MaterialTooltip>
  );
};

export default Tooltip;

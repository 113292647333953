import { Grid, IconButton, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { differenceInHours } from "date-fns";
import get from "lodash.get";
import isNil from "lodash.isnil";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { formatDateTime, toMap } from "../../util";
import Battery, {
  BatteryProps,
  BATTERY_DEFAULT_MAX,
  BATTERY_DEFAULT_MIN,
  calcBatteryPercent,
} from "../Battery";
import { Flag, FlagKey } from "../Flag";
import SensorIcon, {
  SensorStatus,
  SensorType,
  statusColor,
  StatusProps,
} from "../SensorIcon/SensorIcon";

export interface HivePictogramProps {
  saIds?: string[];
  hiveName: string;
  onEdit?: () => void;
  style?: React.CSSProperties;
  gps?: Sensor;
  scale?: Sensor;
  camera?: Sensor;
  microphone?: Sensor;
  humidity?: Sensor;
  temperature?: Sensor;
  innerDeviceBattery?: Sensor;
  secondCamera?: Sensor;
  secondMicrophone?: Sensor;
  hiveFlags?: FlagKey[];
  scaleBatteryCharge?: number;
  ScaleBatteryProps?: BatteryProps;
  lastWeightValue?: { value: number; date: Date };
}

export interface Sensor {
  key: string;
  sensorType: SensorType;
  status: SensorStatus;
}

const StatusColoredBorder = styled.div<StatusProps>(
  (props) => css`
    box-sizing: content-box;
    border: 0.5rem solid ${get(props.theme.palette, statusColor[props.status])};
    border-radius: 4px;
  `,
);

const StyledGrid = styled(Grid)(
  (props) => css`
    flex-grow: 1;
    border: 8px solid ${props.theme.palette.grey[500]};
    border-radius: 4px;
    background-color: ${props.theme.palette.grey[50]};
    padding: ${props.theme.spacing(1)}px;
  `,
);

const GpsBox = styled(StatusColoredBorder)(
  () => css`
    width: 2rem;
    height: 3rem;
  `,
);

const DeviceBox = styled(StatusColoredBorder)(
  (props) => css`
    margin: 0 -${props.theme.spacing(2)}px;
    height: 0.5rem;
    border-radius: 0px;
  `,
);
const BottomBox = styled(StatusColoredBorder)(
  () => css`
    width: 4rem;
    height: 3rem;
  `,
);
const ScaleBox = styled(StatusColoredBorder)(
  (props) => css`
    height: 0.5rem;
    margin-top: ${props.theme.spacing(1)}px;
  `,
);

const StyledName = styled(Typography)(
  (props) => css`
    font-weight: 600;
    color: ${props.theme.palette.grey[900]};
  `,
);
const StyledSmallText = styled(Typography)`
  font-size: 0.75rem;
`;

function getFaultyStatus(sensors: Array<Sensor | undefined>): SensorStatus {
  return (
    sensors.find((s) => s?.status === "error")?.status ??
    sensors.find((s) => s?.status === "warning")?.status ??
    SensorStatus.Ok
  );
}

export const HIVE_FLAG_BADGE_ORDER: FlagKey[] = [
  "anyatlan",
  "csillag",
  "egyesiteni",
  "pergetni",
  "problema",
];

const StyledTopGrid = styled(Grid)`
  position: relative;
`;

// `width` is in pixel because its size is relative to the fix width icons
const StyledHiveFlagBadgesContainer = styled(Grid)`
  max-width: 80px;
`;

const StyledWeightValue = styled(Typography)`
  font-weight: 600;
`;

const StyledLateWeightValue = styled(Typography)(
  (props) => css`
    font-weight: 600;
    color: ${props.theme.palette.grey[500]};
  `,
);

const HivePictogram: React.FC<HivePictogramProps> = ({
  saIds,
  hiveName,
  style,
  onEdit,
  gps,
  scale,
  camera,
  microphone,
  humidity,
  temperature,
  innerDeviceBattery,
  secondCamera,
  secondMicrophone,
  hiveFlags,
  scaleBatteryCharge,
  ScaleBatteryProps,
  lastWeightValue,
}) => {
  const sensors = [
    camera ?? { key: "camera-1", status: SensorStatus.Missing, sensorType: SensorType.Camera },
    microphone ?? { key: "mic-1", status: SensorStatus.Missing, sensorType: SensorType.Microphone },
    humidity ?? { key: "hum-1", status: SensorStatus.Missing, sensorType: SensorType.Humidity },
    temperature ?? {
      key: "temp-1",
      status: SensorStatus.Missing,
      sensorType: SensorType.Temperature,
    },
  ];
  const bottomSensors = [secondCamera, secondMicrophone];
  const middleDevice = innerDeviceBattery;

  const bottomDevice = useMemo(() => {
    return {
      // Device status is the worst status of sensors
      status: bottomSensors.find((sensor) => sensor !== undefined)
        ? getFaultyStatus(bottomSensors)
        : SensorStatus.Missing,
    };
  }, [bottomSensors]);

  const hiveFlagMap = useMemo(() => toMap(hiveFlags ?? [], (key) => key), [hiveFlags]);

  return (
    <StyledTopGrid container justify="space-between" direction="column" style={style}>
      <StyledGrid container item justify="space-between" direction="column">
        <Grid container item justify="space-between">
          <Grid container item direction="column" xs={9}>
            <Grid container item alignItems="center">
              <Grid item>
                <StyledName variant="h6">{hiveName}</StyledName>
              </Grid>
              {onEdit && (
                <Grid item>
                  <IconButton size="small" onClick={onEdit}>
                    <Edit fontSize="inherit" color="primary" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            {!isNil(saIds) &&
              saIds.map((item) => (
                <Grid item key={item}>
                  <StyledSmallText>{item}</StyledSmallText>
                </Grid>
              ))}
          </Grid>
          <Grid item container xs={3} justify="flex-end">
            <GpsBox status={gps?.status ?? SensorStatus.Missing} />
          </Grid>
        </Grid>

        {lastWeightValue && (
          <Grid item>
            {differenceInHours(new Date(), lastWeightValue.date) < 48 ? (
              <StyledWeightValue variant="h6">{lastWeightValue.value} kg</StyledWeightValue>
            ) : (
              <StyledLateWeightValue variant="h6">{lastWeightValue.value} kg</StyledLateWeightValue>
            )}
            <StyledSmallText>{formatDateTime(lastWeightValue.date)}</StyledSmallText>
          </Grid>
        )}

        <Grid item>
          <Grid container direction="row" justify="space-between" alignItems="flex-end">
            <Grid item>
              {sensors.map((item) => (
                <SensorIcon key={item.key} status={item.status} sensorType={item.sensorType} />
              ))}
            </Grid>

            <StyledHiveFlagBadgesContainer
              item
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              {/* Empty Grid item helps in organizing the layout of the badges */}
              <Grid item xs={4} />
              {HIVE_FLAG_BADGE_ORDER.map((hiveFlagKey) => (
                <Grid key={hiveFlagKey} item xs={4}>
                  <Flag id={hiveFlagKey} tooltip disabled={hiveFlagMap.get(hiveFlagKey) == null} />
                </Grid>
              ))}
            </StyledHiveFlagBadgesContainer>
          </Grid>

          <DeviceBox status={middleDevice?.status ?? SensorStatus.Missing} />
        </Grid>

        <Grid container item justify="space-between" alignItems="flex-end">
          <Grid item />
          <Grid item>
            <BottomBox status={bottomDevice.status} />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <StyledSmallText>
                {!isNil(scaleBatteryCharge)
                  ? new Intl.NumberFormat("hu-HU", { style: "percent" }).format(
                      calcBatteryPercent(
                        scaleBatteryCharge,
                        BATTERY_DEFAULT_MIN,
                        BATTERY_DEFAULT_MAX,
                      ),
                    )
                  : "??"}
              </StyledSmallText>
              <Battery charge={scaleBatteryCharge} {...ScaleBatteryProps} />
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
      <Grid item>
        <ScaleBox status={scale?.status ?? SensorStatus.Missing} />
      </Grid>
    </StyledTopGrid>
  );
};

export default HivePictogram;

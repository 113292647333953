import Axios from "axios"
import urlcat from "urlcat"
import type { ResourceConfig } from "./common"

export type DeviceCommandParams = {
	commandType: DeviceCommandType
	commandBody?: DeviceCommandBody
	deviceId: string
}

export type CommandReturnType = {
	rpcId: string
}

export type ExecuteDeviceCommand = (
	params: DeviceCommandParams,
) => Promise<CommandReturnType>

export enum DeviceCommandType {
	Sleep = "STATUS/SLEEP",
	On = "STATUS/ON",
	Off = "STATUS/OFF",
}

// To send sleep time in body
export type DeviceCommandBody = {
	sleepTime: number
}

export type RemoteCallResource = {
	executeDeviceCommand: ExecuteDeviceCommand
}

export function remoteCall(defaultConfig: ResourceConfig): RemoteCallResource {
	const axios = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "rpc/"),
	})

	const executeDeviceCommand: ExecuteDeviceCommand = async ({
		commandType,
		commandBody,
		deviceId,
	}: DeviceCommandParams) =>
		(
			await axios.post<CommandReturnType>(
				"new/",
				commandBody?.sleepTime ?? undefined,
				{
					headers: {
						Authorization: `Bearer ${deviceId}`,
						"X-Command": commandType.toString(),
					},
				},
			)
		).data

	return {
		executeDeviceCommand,
	}
}

import { Tooltip, TooltipProps, useTheme } from "@material-ui/core";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery90Icon from "@material-ui/icons/Battery90";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import BatteryCharging20Icon from "@material-ui/icons/BatteryCharging20";
import BatteryCharging30Icon from "@material-ui/icons/BatteryCharging30";
import BatteryCharging50Icon from "@material-ui/icons/BatteryCharging50";
import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import BatteryCharging80Icon from "@material-ui/icons/BatteryCharging80";
import BatteryCharging90Icon from "@material-ui/icons/BatteryCharging90";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import React from "react";
import styled from "styled-components";

export const BATTERY_DEFAULT_MAX = 4.2;
export const BATTERY_DEFAULT_MIN = 3.3;

export interface BatteryProps {
  charge?: number;
  charging?: boolean;
  max?: number;
  min?: number;
  error?: boolean;
  unit?: string;
  tooltip?: boolean;
  TooltipProps?: TooltipProps;
}

const BatteryIconTooltipContainer = styled.div`
  display: inline-block;
  line-height: 0px;
`;

interface BatteryIconProps {
  charging: boolean;
  level: number;
}

export const calcBatteryPercent = (charge: number, min: number, max: number): number => {
  let percent = (charge - min) / (max - min);
  if (charge < min) {
    percent = 0;
  }
  if (charge > max) {
    percent = 1;
  }
  return percent;
};

function BatteryIcon({ charging, level }: BatteryIconProps): React.ReactElement {
  const theme = useTheme();
  let htmlColor = theme.palette.success.main;
  if (level >= 0.95) {
    return charging ? (
      <BatteryChargingFullIcon htmlColor={htmlColor} />
    ) : (
      <BatteryFullIcon htmlColor={htmlColor} />
    );
  }
  if (level >= 0.9) {
    return charging ? (
      <BatteryCharging90Icon htmlColor={htmlColor} />
    ) : (
      <Battery90Icon htmlColor={htmlColor} />
    );
  }
  if (level >= 0.8) {
    return charging ? (
      <BatteryCharging80Icon htmlColor={htmlColor} />
    ) : (
      <Battery80Icon htmlColor={htmlColor} />
    );
  }
  if (level >= 0.66) {
    return charging ? (
      <BatteryCharging60Icon htmlColor={htmlColor} />
    ) : (
      <Battery60Icon htmlColor={htmlColor} />
    );
  }
  htmlColor = theme.palette.warning.main;
  if (level >= 0.5) {
    return charging ? (
      <BatteryCharging50Icon htmlColor={htmlColor} />
    ) : (
      <Battery50Icon htmlColor={htmlColor} />
    );
  }
  if (level >= 0.33) {
    return charging ? (
      <BatteryCharging30Icon htmlColor={htmlColor} />
    ) : (
      <Battery30Icon htmlColor={htmlColor} />
    );
  }
  htmlColor = theme.palette.error.main;
  if (level <= 0) {
    return <BatteryAlertIcon htmlColor={htmlColor} />;
  }
  return charging ? (
    <BatteryCharging20Icon htmlColor={htmlColor} />
  ) : (
    <Battery20Icon htmlColor={htmlColor} />
  );
}

function Battery({
  charge,
  TooltipProps: tooltipProps,
  error = false,
  charging = false,
  max = BATTERY_DEFAULT_MAX,
  min = BATTERY_DEFAULT_MIN,
  tooltip = true,
  unit = "V",
}: BatteryProps): React.ReactElement {
  const theme = useTheme();
  if (error) {
    return <BatteryAlertIcon htmlColor={theme.palette.error.main} />;
  }
  if (charge === undefined) {
    return <BatteryUnknownIcon htmlColor={theme.palette.grey[500]} />;
  }
  const level = (charge - min) / (max - min);
  const props = { charging, level };
  if (tooltip) {
    const percent = calcBatteryPercent(charge, min, max);

    const title = `${charge}${unit}, ${new Intl.NumberFormat("hu-HU", { style: "percent" }).format(
      percent,
    )}`;
    return (
      <Tooltip title={title} placement="top-start" {...tooltipProps}>
        <BatteryIconTooltipContainer>
          <BatteryIcon {...props} />
        </BatteryIconTooltipContainer>
      </Tooltip>
    );
  }
  return <BatteryIcon {...props} />;
}

export default Battery;

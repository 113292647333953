import Paper from "@material-ui/core/Paper";
import React from "react";
import styled, { css } from "styled-components";
import HiveNote, { HiveNoteProps } from "../HiveNote";

export interface HiveNoteListProps {
  hiveNotes: Array<HiveNoteProps & { key: string }>;
}

const StyledPaper = styled(Paper)(
  () => css`
    border: 1px solid #e0e0e0;
  `,
);

const StyledHiveNote = styled(HiveNote)<{ even: boolean }>(
  ({ even }) => css`
    background-color: ${even ? "#F5F5F5" : "inherit"};
  `,
);

function HiveNoteList({ hiveNotes }: HiveNoteListProps): React.ReactElement {
  return (
    <StyledPaper elevation={0} square>
      {hiveNotes.map((hiveNote, i) => (
        <StyledHiveNote even={i % 2 === 0} {...hiveNote} />
      ))}
    </StyledPaper>
  );
}

export default HiveNoteList;

import { useCallback, useState } from "react";

export interface RefAreaDomain {
  left: string;
  right: string;
}

export interface XAxisDomain {
  left: string;
  right: string;
}

export interface YAxisDomain {
  top: number | string;
  bottom: number | string;
}

interface UseChartZoomReturnType {
  zoomOut: () => void;
  zoom: () => void;
  xAxis: XAxisDomain;
  yAxis: YAxisDomain;
}

export function useChartZoom(
  refArea: RefAreaDomain,
  minValue?: number,
  maxValue?: number,
): UseChartZoomReturnType {
  const [state, setState] = useState({
    left: "dataMin",
    right: "dataMax",
    bottom: minValue ?? "dataMin",
    top: maxValue ?? "dataMax",
  });

  /*   const getAxisYDomain = useCallback(
    (from: number, to: number, ref: string, offset: number): [number, number] => {
      const refData = chartData.slice(
        chartData.findIndex((value) => value.date === from) - 1,
        chartData.findIndex((value) => value.date === to),
      );

      let [bottom, top] = [refData[0][ref], refData[0][ref]] as [number, number];
      refData.forEach((d) => {
        if (d[ref] > top) top = d[ref];
        if (d[ref] < bottom) bottom = d[ref];
      });

      return [(bottom | 0) - offset, (top | 0) + offset];
    },
    [chartData],
  ); */

  const zoomOut = useCallback(() => {
    setState({
      left: "dataMin",
      right: "dataMax",
      bottom: minValue ?? "dataMin",
      top: maxValue ?? "dataMax",
    });
  }, [minValue, maxValue]);

  const zoom = useCallback((): void => {
    if (refArea.left === refArea.right || refArea.right === "") {
      return;
    }

    let { left, right } = refArea;
    // xAxis domain
    if (refArea.left > refArea.right) [left, right] = [refArea.right, refArea.left];

    // yAxis domain
    /*  const [bottom, top] = getAxisYDomain(Number(refAreaLeft), Number(refAreaRight), "value", 1); */

    setState({
      ...state,
      left,
      right,
    });
  }, [state, refArea]);

  return {
    zoomOut,
    zoom,
    xAxis: { left: state.left, right: state.right },
    yAxis: { bottom: state.bottom, top: state.top },
  };
}

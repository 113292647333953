import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React from "react";

export interface HideOnScrollProps {
  /**
   * Injected by the documentation to work in an `iframe`.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

const HideOnScroll: React.FC<HideOnScrollProps> = ({ window, children }) => {
  /**
   * Note that you normally won't need to set the `window` ref as `useScrollTrigger`
   * will default to `window`.
   * This is only being set here because the demo is in an `iframe`.
   */
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;

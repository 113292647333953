import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { Hive, Page, PaginationVariables } from "../client"

export function useHiveListQuery(
	variables: PaginationVariables,
	options?: UseQueryOptions<Page<Hive>>,
): UseQueryResult<Page<Hive>> {
	const client = useClient()
	return useQuery(
		["hiveList", variables],
		async () => client.hive.list(variables),
		options,
	)
}

import { useTheme } from "@material-ui/core";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import React from "react";
import styled, { css } from "styled-components";
import { FlagKey } from "../Flag";
import { FLAGS } from "../Flag/Flag";

export interface FlagChipProps {
  id: FlagKey;
  active?: boolean;
  chipProps?: ChipProps;
}

const StyledChip = styled(Chip)<{ $backgroundColor: string; contrastText: string }>(
  ({ $backgroundColor, contrastText }) => {
    const colorOverride = css`
      background-color: ${$backgroundColor};
      color: ${contrastText};
    `;
    return css`
      ${colorOverride}
      &:hover,&:focus {
        ${colorOverride}
      }
    `;
  },
);

export const FlagChip: React.FC<FlagChipProps> = ({ id, chipProps, active = false }) => {
  const { text, icon: Icon, color: backgroundColor } = FLAGS[id];
  const theme = useTheme();
  const contrastText = theme.palette.getContrastText(backgroundColor);
  if (active) {
    return (
      <StyledChip
        icon={<Icon style={{ color: contrastText }} />}
        label={text}
        $backgroundColor={backgroundColor}
        contrastText={contrastText}
        {...chipProps}
      />
    );
  }
  return <Chip icon={<Icon />} label={text} {...chipProps} />;
};

import Axios from "axios"
import urlcat from "urlcat"
import type {
	GetByIdFunction,
	ListFunction,
	Page,
	ResourceConfig,
} from "./common"

/** https://bee-box.io/ng/docs/rest/models.html#quarantinejson */
export type Quarantine = {
	id: number
	name: string
	active_datetimes: [string | null, string | null]
	is_active: true
	geojson: GeoJSON.Feature<GeoJSON.Polygon>
}

export type ListQuarantines = ListFunction<Quarantine>

export type GetQuarantine = GetByIdFunction<Quarantine>

export type QuarantineResource = {
	list: ListQuarantines
	get: GetQuarantine
}

/**
 * Quarantine
 *
 * https://bee-box.io/ng/docs/rest/index.html#api-quarantine
 */
export function quarantine(defaultConfig: ResourceConfig): QuarantineResource {
	const axios = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "quarantine/"),
	})

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-quarantine-list- */
	const list: ListQuarantines = async (variables) =>
		(await axios.get<Page<Quarantine>>("list/", { params: variables })).data

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-quarantine-(int-quarantine_id)- */
	const get: GetQuarantine = async ({ id }) =>
		(await axios.get<Quarantine>(urlcat(":id/", { id }))).data

	return {
		list,
		get,
	}
}

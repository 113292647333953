import React from "react";
import NumberMeasurementChart, { NumberMeasurementChartProps } from "../NumberMeasurementsChart";

export type MassChartProps = NumberMeasurementChartProps;

const MassChart: React.FC<MassChartProps> = ({
  data,
  unit = "kg",
  displayLegends = false,
  ...props
}) => {
  return (
    <NumberMeasurementChart
      data={data}
      unit={unit}
      displayLegends={displayLegends}
      min={35}
      max={60}
      bottomAxisLabel="Tömeg"
      {...props}
    />
  );
};

export default MassChart;

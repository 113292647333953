import { isAfter, isBefore } from "date-fns";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import maxBy from "lodash.maxby";
import minBy from "lodash.minby";
import { useMemo } from "react";
import type { NumberMeasurementChartDataType } from "../components/NumberMeasurementsChart";
import { RechartSeries } from "../components/RechartsChart";
import { formatChartDateInInterval, formatChartDateTime } from "../util";

interface NumberMeasurementChartHookReturn {
  dateValueFormat: (value: number) => string;
  dataMax: number;
  domainMin: number | undefined;
  domainMax: number | undefined;
}

export function calcMaxDataValue(data: RechartSeries<NumberMeasurementChartDataType>): number {
  const value = maxBy(
    data.map((item) => maxBy(item.data, (it) => Number(it.value))),
    (item) => Number(item?.value),
  );

  return Number(value?.value);
}

// Compare with Number(null) is meaningless because it's converted to 0
// Use Infinty on null values, so if the other value is lower it is the minimum
export function calcMinDataValue(data: RechartSeries<NumberMeasurementChartDataType>): number {
  const value = minBy(
    data.map((item) =>
      minBy(item.data, (it) => {
        if (!isNil(it.value)) return it.value;
        return Infinity;
      }),
    ),
    (item) => {
      if (!isNil(item?.value)) return item?.value;
      return Infinity;
    },
  );

  if (value?.value != null) return value.value;
  return Infinity;
}

export interface UseNumberMeasurementChartVariables {
  data: RechartSeries<NumberMeasurementChartDataType>;
  unit?: string;
  min?: number;
  max?: number;
}

export function useNumberMeasurementChart({
  data,
  min,
  max,
}: UseNumberMeasurementChartVariables): NumberMeasurementChartHookReturn {
  const dataMin = calcMinDataValue(data);
  const dataMax = calcMaxDataValue(data);

  const domainMin = !isNil(min) && dataMin < min ? dataMin : min ?? undefined;
  const domainMax = !isNil(max) && dataMax > max ? dataMax : max ?? undefined;

  const { startDate, endDate } = useMemo(() => {
    if (isEmpty(data)) {
      return { startDate: undefined, endDate: undefined };
    }

    const dateValues = data.map((value) => ({
      minDate: !isNil(value) && !isEmpty(value.data) ? value.data[0].dateInMs : undefined,
      maxDate:
        !isNil(value) && !isEmpty(value.data)
          ? value.data[value.data.length - 1].dateInMs
          : undefined,
    }));

    return dateValues.reduce<{ startDate?: Date; endDate?: Date }>(
      (acc, value) => {
        if (isNil(value.minDate) || isNil(value.maxDate)) {
          return acc;
        }

        try {
          const valMin = new Date(value.minDate);
          const valMax = new Date(value.maxDate);
          if (isNil(acc.startDate)) {
            acc.startDate = valMin;
          }
          if (isNil(acc.endDate)) {
            acc.endDate = valMax;
          }

          acc.startDate = isBefore(acc.startDate, valMin) ? acc.startDate : valMin;
          acc.endDate = isAfter(acc.endDate, valMax) ? acc.endDate : valMax;
        } catch {
          throw new Error("Wrong date values and format");
        }
        return acc;
      },

      { startDate: undefined, endDate: undefined },
    );
  }, [data]);

  const dateValueFormat: (value: number) => string = (value) => {
    // The chart XAxis gives infinity values when no data given
    if (value > 0 && value < Infinity) {
      if (!isNil(startDate) && !isNil(endDate))
        return formatChartDateInInterval(new Date(value), startDate, endDate);
      return formatChartDateTime(new Date(value));
    }
    return "No data found";
  };

  return { domainMin, domainMax, dataMax, dateValueFormat };
}

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { Device, IdVariable } from "../client"

export function useDeviceQuery(
	variables: IdVariable,
	options?: UseQueryOptions<Device>,
): UseQueryResult<Device> {
	const client = useClient()
	return useQuery(
		["device", variables],
		async () => client.device.get(variables),
		options,
	)
}

import TempIcon from "@material-ui/icons/AcUnit";
import MicIcon from "@material-ui/icons/Mic";
import HumidityIcon from "@material-ui/icons/Opacity";
import CameraIcon from "@material-ui/icons/Videocam";
import get from "lodash.get";
import React from "react";
import styled, { css } from "styled-components";

export interface DeviceIconProps {
  status: SensorStatus;
  sensorType: SensorType;
}

export enum SensorStatus {
  Error = "error",
  Warning = "warning",
  Ok = "ok",
  Missing = "missing",
}

export enum SensorType {
  Camera = "camera",
  Microphone = "microphone",
  Humidity = "humidity",
  Temperature = "temperature",
  Scale = "scale",
  GPS = "gps",
}

export interface StatusProps {
  status: SensorStatus;
}

export const statusColor: Record<SensorStatus, string> = {
  error: "error.main",
  warning: "warning.main",
  ok: "success.main",
  missing: "grey[500]",
};

const sensorIcon: Record<SensorType, JSX.Element> = {
  camera: <CameraIcon />,
  microphone: <MicIcon />,
  humidity: <HumidityIcon />,
  temperature: <TempIcon />,
  gps: <></>,
  scale: <></>,
};

const ColoredIcon = styled.span<StatusProps>(
  (props) => css`
    color: ${get(props.theme.palette, statusColor[props.status])};
  `,
);

const DeviceIcon: React.FC<DeviceIconProps> = ({ status, sensorType }) => {
  return <ColoredIcon status={status}>{sensorIcon[sensorType]}</ColoredIcon>;
};

export default DeviceIcon;

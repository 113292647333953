import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type {
	EmptyObject,
	HiveGroup,
	UpdateHiveGroupVariables,
} from "../client"

export function useUpdateHiveGroupMutation(
	options?: UseMutationOptions<EmptyObject, unknown, UpdateHiveGroupVariables>,
): UseMutationResult<EmptyObject, unknown, UpdateHiveGroupVariables> {
	const queryClient = useQueryClient()
	const client = useClient()
	return useMutation(client.hiveGroup.update, {
		...options,
		onSuccess: async (data, variables) => {
			const { id, name } = variables
			await queryClient.invalidateQueries("hiveGroupList")
			const hiveGroupCacheKey = ["hiveGroup", { id }]
			const prevHiveGroup = queryClient.getQueryData<HiveGroup>(
				hiveGroupCacheKey,
			)
			if (prevHiveGroup) {
				queryClient.setQueryData<HiveGroup>(hiveGroupCacheKey, {
					...prevHiveGroup,
					name,
				})
			}
		},
	})
}

import type { AxiosInstance, AxiosTransformer } from "axios"
import Axios from "axios"
import parseISO from "date-fns/parseISO"
import type { Point } from "geojson"
import urlcat from "urlcat"
import { formatDate } from "../utils/format"
import type {
	GetByIdFunction,
	IdVariable,
	ListFunction,
	Page,
	ResourceConfig,
} from "./common"
import { mergeWithDetailedQueryParams } from "./common"

/** https://bee-box.io/ng/docs/rest/models.html#devicepropertiesjson */
export type DeviceProperties = {
	g2kg?: boolean
	bee_detection?: number
	bee_activity_device_id?: number
	bee_sound_analytics_device_id?: number
	saId?: string
}

/** https://bee-box.io/ng/docs/rest/models.html#devicejson */
export type Device = {
	id: number
	name: string
	description: string
	serialNumber: string
	hardwareVersion: string
	hiveGroupId: number
	hiveId: number
	sensors: Sensor[]
	status?: Status
	properties: DeviceProperties
}

export type MeasurementValue =
	| boolean
	| number
	| string
	| { amplitude: number; frequency: number }
	| Point
	| null

export type MeasurementsResponse<
	MV extends MeasurementValue = MeasurementValue
> = {
	data: Array<[string, MV]>
}

export enum Status {
	Ok = "OK",
}

export type SensorType = {
	namespace: string
	name: string
	description: string
}

export type SensorDisplay = {
	name: string
	format: string
	enum?: string | null
	unit: string
	polynomial: string
	limits: []
}

export type SensorDataType = {
	namespace: string
	value: string
	display: SensorDisplay
}

export type Sensor<MV extends MeasurementValue = MeasurementValue> = {
	id: string
	name: string
	description: string
	key: string
	deviceId: number
	hiveGroupId?: number
	hiveId: number
	sensorType: SensorType
	dataType: SensorDataType
	status: Status
	measurement?: {
		relativeTime: number
		absoluteTime: string
		value: MV
	}
}

export type SensorMeasurementsIdVariables = {
	deviceId: number
	sensorKey: string
}

export type SensorMeasurementsSeriesVariables = SensorMeasurementsIdVariables & {
	from: Date
	to: Date
}

export type SensorMeasurementsNavigatorVariables = SensorMeasurementsIdVariables & {
	/** 16 <= `samples` <= 2048 */
	samples?: number
}

export type ListDevices = ListFunction<Device>

export type GetDevice = GetByIdFunction<Device>

export type GetSensorMeasurementsSeries = <
	MV extends MeasurementValue = MeasurementValue
>(
	variables: SensorMeasurementsSeriesVariables,
) => Promise<Measurements<MV>>
export type GetSensorMeasurementsNavigator = <
	MV extends MeasurementValue = MeasurementValue
>(
	variables: SensorMeasurementsNavigatorVariables,
) => Promise<Measurements<MV>>

export type DeviceResource = {
	list: ListDevices
	get: GetDevice
	getSensorMeasurementsSeries: GetSensorMeasurementsSeries
	getSensorMeasurementsNavigator: GetSensorMeasurementsNavigator
}

export type Measurement<MV extends MeasurementValue> = {
	date: Date
	value: MV
}

export type Measurements<MV extends MeasurementValue> = Array<Measurement<MV>>

function transformMeasurementsResponse<
	MV extends MeasurementValue = MeasurementValue
>(response: MeasurementsResponse<MV>): Measurements<MV> {
	return response.data.map(([theISODate, value]) => ({
		date: parseISO(theISODate),
		value,
	}))
}

function castArray<T>(value: T | T[]): T[] {
	return Array.isArray(value) ? value : [value]
}

function extendAxiosTransformResponse(
	axios: AxiosInstance,
	transformResponse: AxiosTransformer | AxiosTransformer[] | undefined,
): AxiosTransformer[] {
	const {
		defaults: { transformResponse: defaultTransformResponse },
	} = axios
	return [
		...castArray(defaultTransformResponse),
		...castArray(transformResponse),
	].filter((tr): tr is AxiosTransformer => !!tr)
}

/**
 * Device
 *
 * https://bee-box.io/ng/docs/rest/index.html#api-device
 */
export function device(defaultConfig: ResourceConfig): DeviceResource {
	const axios = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "device/"),
	})

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-device-list- */
	const list: ListDevices = async (variables) =>
		(
			await axios.get<Page<Device>>("list/", {
				params: mergeWithDetailedQueryParams(variables ?? {}),
			})
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-device-(int-device_id)- */
	const get: GetDevice = async ({ id }: IdVariable) =>
		(
			await axios.get<Device>(urlcat(":id/", { id }), {
				params: mergeWithDetailedQueryParams({}),
			})
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-device-(int-device_id)-sensor-(str-sensor_key)-measurement-series- */
	const getSensorMeasurementsSeries: GetSensorMeasurementsSeries = async <
		MV extends MeasurementValue = MeasurementValue
	>({
		deviceId,
		sensorKey,
		from,
		to,
	}: SensorMeasurementsSeriesVariables) =>
		(
			await axios.get<Measurements<MV>>(
				urlcat(":deviceId/sensor/:sensorKey/measurements/series/", {
					deviceId,
					sensorKey,
				}),
				{
					params: {
						date_from: formatDate(from),
						date_to: formatDate(to),
					},
					transformResponse: extendAxiosTransformResponse(
						axios,
						transformMeasurementsResponse,
					),
				},
			)
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-device-(int-device_id)-sensor-(str-sensor_key)-measurement-navigator- */
	const getSensorMeasurementsNavigator: GetSensorMeasurementsNavigator = async <
		MV extends MeasurementValue = MeasurementValue
	>({
		deviceId,
		sensorKey,
		samples,
	}: SensorMeasurementsNavigatorVariables) =>
		(
			await axios.get<Measurements<MV>>(
				urlcat(":deviceId/sensor/:sensorKey/measurements/navigator/", {
					deviceId,
					sensorKey,
				}),
				{
					params: { samples },
					transformResponse: extendAxiosTransformResponse(
						axios,
						transformMeasurementsResponse,
					),
				},
			)
		).data

	return {
		list,
		get,
		getSensorMeasurementsSeries,
		getSensorMeasurementsNavigator,
	}
}

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type {
	MultipleAggregatedSensorsMeasurementsVariables,
	SensorMeasurementsAggregate,
} from "../client/custom"

export function useMultipleAggregatedSensorMeasurements(
	variables: MultipleAggregatedSensorsMeasurementsVariables,
	options?: UseQueryOptions<SensorMeasurementsAggregate[]>,
): UseQueryResult<SensorMeasurementsAggregate[]> {
	const client = useClient()
	return useQuery(
		["multipleAggregatedSensorMeasurements", variables],
		async () =>
			client.custom.getMultipleAggregatedSensorMeasurements(variables),
		options,
	)
}

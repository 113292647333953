import { AppBarProps as MUIAppBarProps } from "@material-ui/core";
import MUIAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import React from "react";
import styled, { css } from "styled-components";
import BackIconButton from "../BackIconButton";
import LogoutIconButton from "../LogoutIconButton";

export interface AppBarProps extends MUIAppBarProps {
  onBack: () => void;
  onLogout: () => void;
  title?: string;
  titleProps?: TypographyProps;
  rightIcons?: React.ReactElement[];
  inputField?: React.ReactElement;
}

const StyledAppBar = styled(MUIAppBar)(
  (props) => css`
    background-color: ${props.theme.palette.primary.main};
    flex-grow: 1;
  `,
);

const StyledNavigation = styled(BackIconButton)(
  (props) => css`
    margin-right: ${props.theme.spacing(2)};
  `,
);

const StyledTitle = styled(Typography)`
  flex-grow: 1;
`;

const AppBar: React.FC<AppBarProps> = ({
  onBack,
  onLogout,
  title,
  titleProps,
  rightIcons,
  inputField,
  ...props
}) => {
  return (
    <StyledAppBar position="sticky" {...props}>
      <Toolbar>
        <StyledNavigation onClick={onBack} edge="start" color="inherit" />

        <StyledTitle variant="h6" {...titleProps}>
          {title}
        </StyledTitle>

        {inputField && inputField}

        {rightIcons && rightIcons}
        <LogoutIconButton onClick={onLogout} edge="end" color="inherit" />
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBar;

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { HiveNoteOptions } from "../client"

export function useHiveNoteOptionsQuery(
	options?: UseQueryOptions<HiveNoteOptions>,
): UseQueryResult<HiveNoteOptions> {
	const client = useClient()
	return useQuery("hiveNoteOptions", client.hive.getNoteOptions, options)
}

import Axios from "axios"
import urlcat from "urlcat"
import { objectToFormData } from "../utils/objectToFormData"
import type {
	EmptyObject,
	GetByIdFunction,
	ListFunction,
	Page,
	ResourceConfig,
	UpdateVariables,
} from "./common"
import { mergeWithDetailedQueryParams } from "./common"
import type { Device, Sensor } from "./device"

export type DeviceInHive = Omit<Device, "sensors"> & {
	sensors: string[]
}

export type HiveFlag = {
	key: "anyatlan" | "csillag" | "egyesiteni" | "pergetni" | "problema"
	text: string
	color?: string
}

/** `HiveNoteFlag` is the same as `HiveFlag` for now, it is subject to change. */
export type HiveNoteFlag = HiveFlag

/** TODO: link */
export type HiveNoteOptions = {
	flags: HiveNoteFlag[]
}

/** TODO: link */
export type HiveNote = {
	id: number
	hiveId: number
	createdAt: string
	flags: HiveFlag[]
	text: string | null
	attachment: string | null
}

/** https://bee-box.io/ng/docs/rest/models.html#devicejson */
export type Hive = {
	id: number
	name: string
	description: string
	hiveGroupId: number
	geojson: GeoJSON.Feature<GeoJSON.Polygon>
	devices: DeviceInHive[]
	sensors: Sensor[]
	status: string
	flags: HiveFlag[]
}

export type ListHives = ListFunction<Hive>
export type GetHive = GetByIdFunction<Hive>

export type UpdateHiveVariables = UpdateVariables<{
	name?: string
	flags?: string[] | null
}>

export type UpdateHive = (
	variables: UpdateHiveVariables,
) => Promise<EmptyObject>

export type GetHiveNoteOptions = () => Promise<HiveNoteOptions>

export type GetHiveNotesVariables = { hiveId: number }
export type GetHiveNotes = (
	variables: GetHiveNotesVariables,
) => Promise<HiveNote[]>

export type CreateHiveNoteVariables = {
	hiveId: number
	createdAt: Date
	flags?: string[]
	text?: string
	attachment?: File
}
export type CreateHiveNote = (
	variables: CreateHiveNoteVariables,
) => Promise<HiveNote>

export type HiveNoteIdVariables = {
	hiveId: number
	noteId: number
}

export type GetHiveNoteVariables = HiveNoteIdVariables
export type GetHiveNote = (variables: GetHiveNoteVariables) => Promise<HiveNote>

export type DeleteHiveNoteVariables = HiveNoteIdVariables
export type DeleteHiveNote = (
	variables: DeleteHiveNoteVariables,
) => Promise<HiveNoteIdVariables>

export type UpdateHiveNoteVariables = HiveNoteIdVariables & {
	createdAt: Date
	flags?: string[] | null
	text?: string | null
	attachment?: File | null
}
export type UpdateHiveNote = (
	variables: UpdateHiveNoteVariables,
) => Promise<HiveNote>

export type HiveInspectionSheet = unknown

export type GetHiveInspectionSheetVariables = { hiveId: number }
export type GetHiveInspectionSheet = (
	variables: GetHiveInspectionSheetVariables,
) => Promise<HiveInspectionSheet>

export type SetHiveInspectionSheetVariables = {
	hiveId: number
	inspectionSheet: string
}
export type SetHiveInspectionSheet = (
	variables: SetHiveInspectionSheetVariables,
) => Promise<HiveInspectionSheet>

export type HiveResource = {
	list: ListHives
	get: GetHive
	update: UpdateHive
	getNoteOptions: GetHiveNoteOptions
	getNotes: GetHiveNotes
	createNote: CreateHiveNote
	getNote: GetHiveNote
	deleteNote: DeleteHiveNote
	updateNote: UpdateHiveNote
	getInspectionSheet: GetHiveInspectionSheet
	setInspectionSheet: SetHiveInspectionSheet
}

/**
 * Hive
 *
 * https://bee-box.io/ng/docs/rest/index.html#api-hive
 */
export function hive(defaultConfig: ResourceConfig): HiveResource {
	const apiv1 = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "hive/"),
	})
	const apiv2 = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "v2/hives/"),
	})

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-hive-list- */
	const list: ListHives = async (variables) =>
		(
			await apiv1.get<Page<Hive>>("list/", {
				params: mergeWithDetailedQueryParams(variables ?? {}),
			})
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-hive-(int-hive_id)- */
	const get: GetHive = async ({ id }) =>
		(
			await apiv1.get<Hive>(urlcat(":id/", { id }), {
				params: mergeWithDetailedQueryParams({}),
			})
		).data

	/** https://bee-box.io/ng/docs/rest/index.html#post--api-hive-(int-hive_id)-patch- */
	const update: UpdateHive = async ({ id, name, flags }) => {
		const { data } = await apiv1.post<EmptyObject>(
			urlcat(":id/patch/", { id }),
			objectToFormData({ name, flags }),
		)
		return data
	}

	/** TODO: link */
	const getNoteOptions: GetHiveNoteOptions = async () => {
		return (await apiv2.options<HiveNoteOptions>("notes/")).data
	}

	/** TODO: link */
	const getNotes: GetHiveNotes = async ({ hiveId }) => {
		return (
			await apiv2.get<{ models: HiveNote[] }>(
				urlcat(":hiveId/notes/", { hiveId }),
			)
		).data.models
	}

	const createNote: CreateHiveNote = async ({ hiveId, ...variables }) => {
		return (
			await apiv2.post<HiveNote>(
				urlcat(":hiveId/notes/", { hiveId }),
				objectToFormData({ ...variables }),
			)
		).data
	}

	const getNote: GetHiveNote = async ({ hiveId, noteId }) => {
		return (
			await apiv2.get<HiveNote>(
				urlcat(":hiveId/notes/:noteId/", { hiveId, noteId }),
			)
		).data
	}

	const deleteNote: DeleteHiveNote = async ({ hiveId, noteId }) => {
		return (
			await apiv2.delete<HiveNoteIdVariables>(
				urlcat(":hiveId/notes/:noteId/", { hiveId, noteId }),
			)
		).data
	}

	const updateNote: UpdateHiveNote = async ({
		hiveId,
		noteId,
		...variables
	}) => {
		return (
			await apiv2.patch<HiveNote>(
				urlcat(":hiveId/notes/:noteId/", { hiveId, noteId }),
				objectToFormData({ ...variables }),
			)
		).data
	}

	const getInspectionSheet: GetHiveInspectionSheet = async (vars) => {
		return (
			await apiv1.get<HiveInspectionSheet>(urlcat(":hiveId/plate/", vars))
		).data
	}

	const setInspectionSheet: SetHiveInspectionSheet = async ({
		hiveId,
		inspectionSheet,
	}) => {
		return (
			await apiv1.post<HiveInspectionSheet>(
				urlcat(":hiveId/plate/", { hiveId }),
				objectToFormData({ plate: inspectionSheet }),
			)
		).data
	}

	return {
		list,
		get,
		update,
		getNoteOptions,
		getNotes,
		createNote,
		getNote,
		deleteNote,
		updateNote,
		getInspectionSheet,
		setInspectionSheet,
	}
}

import type { AxiosRequestConfig } from "axios"

/** https://bee-box.io/ng/docs/rest/index.html#gyakori-query-argumentumok */
export type CommonQueryParams =
	| { expanded: false }
	| {
			expanded: true
			withstatus?: boolean
			withmeasurement?: boolean
	  }

export type DetailedQueryParams = {
	expanded: true
	withstatus: true
	withmeasurement: true
}

export const DETAILED_QUERY_PARAMS: DetailedQueryParams = {
	expanded: true,
	withstatus: true,
	withmeasurement: true,
}

export function mergeWithDetailedQueryParams<
	Params extends Record<string, unknown>
>(params: Params): Params & DetailedQueryParams {
	return {
		...params,
		...DETAILED_QUERY_PARAMS,
	}
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}

export type ListFunction<Data> = (
	variables?: PaginationVariables,
) => Promise<Page<Data>>
export type GetByIdFunction<Data> = (variable: IdVariable) => Promise<Data>

export type ResourceConfig = RequiredBy<AxiosRequestConfig, "baseURL">

export type IdVariable = {
	id: number
}

export type UpdateVariables<Variables> = IdVariable & Variables

export type EmptyObject = { [key: string]: never }

/** https://bee-box.io/ng/docs/rest/index.html#rest-paging */
export type PaginationVariables = {
	page?: number
	pagesize?: number
}

/** https://bee-box.io/ng/docs/rest/index.html#rest-paging */
export type Page<Data> = {
	recordsTotal: number
	recordsFiltered: number
	data: Data[]
}

import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { EmptyObject, UpdateHiveVariables } from "../client"

export function useUpdateHiveMutation(
	options?: UseMutationOptions<EmptyObject, unknown, UpdateHiveVariables>,
): UseMutationResult<EmptyObject, unknown, UpdateHiveVariables> {
	const queryClient = useQueryClient()
	const client = useClient()
	return useMutation(client.hive.update, {
		...options,
		onSuccess: async (data, variables) => {
			const { id } = variables
			await queryClient.invalidateQueries("hiveList")
			await queryClient.invalidateQueries(["hive", { id }])
		},
	})
}

import React from "react";
import NumberMeasurementChart, { NumberMeasurementChartProps } from "../NumberMeasurementsChart";

export type TemperatureChartProps = NumberMeasurementChartProps;

const TemperatureChart: React.FC<TemperatureChartProps> = ({
  data,
  unit = "℃",
  displayLegends = false,
  ...props
}) => {
  return (
    <NumberMeasurementChart
      data={data}
      unit={unit}
      displayLegends={displayLegends}
      bottomAxisLabel="Hőmérséklet"
      {...props}
    />
  );
};

export default TemperatureChart;

import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import type { EmptyObject } from ".."
import { useClient } from "../BeeBoxApiClientContext"

export function useLogoutMutation(
	options?: UseMutationOptions<EmptyObject>,
): UseMutationResult<EmptyObject, unknown, void> {
	const client = useClient()
	const queryClient = useQueryClient()
	return useMutation(client.session.logout, {
		...options,
		onSuccess: async () => {
			await queryClient.invalidateQueries("me")
			queryClient.clear()
		},
	})
}

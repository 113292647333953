import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { HiveGroup, Page, PaginationVariables } from "../client"

export function useHiveGroupListQuery(
	variables: PaginationVariables,
	options?: UseQueryOptions<Page<HiveGroup>>,
): UseQueryResult<Page<HiveGroup>> {
	const client = useClient()
	return useQuery(
		["hiveGroupList", variables],
		async () => client.hiveGroup.list(variables),
		options,
	)
}

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type {
	GetHiveInspectionSheetVariables,
	HiveInspectionSheet,
} from "../client"

export function useHiveInspectionSheetQuery(
	variables: GetHiveInspectionSheetVariables,
	options?: UseQueryOptions<HiveInspectionSheet>,
): UseQueryResult<HiveInspectionSheet> {
	const client = useClient()
	return useQuery(
		["hiveInspectionSheet", variables],
		async () => client.hive.getInspectionSheet(variables),
		options,
	)
}

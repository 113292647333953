import { differenceInDays } from "date-fns";
import compareAsc from "date-fns/compareAsc";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import round from "lodash.round";
import { Measurement, MeasurementValue } from "./types";

export const DATE_FORMAT = "y-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const CHART_DATE_FORMAT = "yyyy LLL d";
export const CHART_DATE_TIME_FORMAT = `${CHART_DATE_FORMAT} ${TIME_FORMAT}`;

export function compareDates(a: Date | string, b: Date | string): number {
  return compareAsc(new Date(a), new Date(b));
}

export function sortMeasurementsByDate<V extends MeasurementValue>(
  measurements: Array<Measurement<V>>,
  direction = 1,
): Array<Measurement<V>> {
  return measurements.sort(([a], [b]) => direction * compareDates(a, b));
}

export function formatISODate(date: Date | string, formatOption: string): string {
  const d = typeof date === "string" ? parseISO(date) : date;
  return format(d, formatOption);
}

export function formatDate(date: Date | string): string {
  return formatISODate(date, DATE_FORMAT);
}

export function formatDateTime(date: Date | string): string {
  return formatISODate(date, DATE_TIME_FORMAT);
}

export function formatChartDate(date: Date | string): string {
  return formatISODate(date, CHART_DATE_FORMAT);
}

export function formatChartTime(date: Date | string): string {
  return formatISODate(date, TIME_FORMAT);
}

export function formatChartDateTime(date: Date | string): string {
  return formatISODate(date, CHART_DATE_TIME_FORMAT);
}

export function formatNumber(number: number): number {
  return round(number, 2);
}

export function formatChartValue(value: number, unit?: string, quantity?: number): string {
  return (
    formatNumber(value).toString() +
    (unit !== undefined ? ` ${unit}` : "") +
    (quantity !== undefined ? ` (${quantity})` : "")
  );
}

export function formatChartDateInInterval(value: string | Date, start: Date, end: Date): string {
  let formatString: string;

  if (differenceInDays(end, start) > 365) {
    formatString = "y MMM d HH:mm";
  } else if (isSameDay(end, start)) {
    formatString = "HH:mm";
  } else {
    formatString = "MMM d HH:mm";
  }

  return formatISODate(value, formatString);
}

export function toMap<V, K = string>(array: V[], iteratee: (item: V) => K): Map<K, V> {
  return new Map(array.map((v) => [iteratee(v), v]));
}

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { Page, PaginationVariables, Quarantine } from "../client"

export function useQuarantineListQuery(
	variables: PaginationVariables,
	options?: UseQueryOptions<Page<Quarantine>>,
): UseQueryResult<Page<Quarantine>> {
	const client = useClient()
	return useQuery(
		["quarantineList", variables],
		async () => client.quarantine.list(variables),
		options,
	)
}

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { Measurements, MeasurementValue } from "../client"
import type { GetMultipleSensorsMeasurementsSeries } from "../client/custom"

export function useMultipleSensorsMeasurementsSeries<
	MV extends MeasurementValue = MeasurementValue
>(
	variables: Parameters<GetMultipleSensorsMeasurementsSeries>[0],
	options?: UseQueryOptions<Array<Measurements<MV>>>,
): UseQueryResult<Array<Measurements<MV>>> {
	const client = useClient()
	return useQuery<Array<Measurements<MV>>>(
		["multipleSensorsMeasurementsSeries", variables],
		async () => client.custom.getMultipleSensorsMeasurementsSeries(variables),
		options,
	)
}

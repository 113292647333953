import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type {
	HiveInspectionSheet,
	SetHiveInspectionSheetVariables,
} from "../client"

export function useSetHiveInspectionSheetMutation(
	options?: UseMutationOptions<
		HiveInspectionSheet,
		unknown,
		SetHiveInspectionSheetVariables
	>,
): UseMutationResult<
	HiveInspectionSheet,
	unknown,
	SetHiveInspectionSheetVariables
> {
	const client = useClient()
	const queryClient = useQueryClient()
	return useMutation(client.hive.setInspectionSheet, {
		onSuccess: async (_, { hiveId }) => {
			await queryClient.invalidateQueries(["hiveInspectionSheet", { hiveId }])
		},
		...options,
	})
}

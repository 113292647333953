import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#083050",
      light: "#395973",
      dark: "#072B48",
    },
    secondary: {
      main: "#FFCB05",
      light: "#FFD537",
      dark: "#E6B705",
    },
    error: {
      main: "#FE3D20",
      light: "#FE654F",
      dark: "#DF1F01",
    },
    success: {
      main: "#54A075",
      light: "#6CB28A",
      dark: "#417B5A",
      contrastText: "#23292F",
    },
    info: {
      main: "#0995EC",
      light: "#26A7F7",
      dark: "#077CC5",
    },
    warning: {
      main: "#FFC847",
      light: "#FFDA85",
      dark: "#FFB60A",
      contrastText: "#23292F",
    },
    grey: {
      50: "#F2F3F5",
      100: "#E6E8EB",
      200: "#D3D6DD",
      300: "#C1C6CF",
      500: "#616B75",
      700: "#343D46",
      900: "#23292F",
    },

    text: {
      primary: "#23292F",
      secondary: "#343D46",
      disabled: "#616B75",
      hint: "#616B75",
    },
    divider: "#C1C6CF",
  },
  typography: {
    fontFamily: "Montserrat, Roboto, Arial, sans-serif",
  },
});

export const chartTextStyle = {
  fontFamily: "Montserrat",
  color: theme.palette.text.primary,
  fill: theme.palette.text.primary,
};

export const chartTooltipStyle = {
  fontSize: "0.85em",
  fontWeight: 400,
  ...chartTextStyle,
};

export const chartTheme = {
  labels: { text: chartTextStyle },
  axis: {
    legend: {
      text: chartTextStyle,
    },
    ticks: {
      text: chartTextStyle,
    },
  },
  legends: {
    text: chartTextStyle,
  },
  tooltip: {
    basic: chartTooltipStyle,
  },
};

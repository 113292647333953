import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { CreateHiveNoteVariables, HiveNote } from "../client"

export function useCreateHiveNoteMutation(
	options?: UseMutationOptions<HiveNote, unknown, CreateHiveNoteVariables>,
): UseMutationResult<HiveNote, unknown, CreateHiveNoteVariables> {
	const queryClient = useQueryClient()
	const client = useClient()
	return useMutation(client.hive.createNote, {
		...options,
		onSuccess: async (data, variables) => {
			await queryClient.invalidateQueries([
				"hiveNotes",
				{ hiveId: variables.hiveId },
			])
		},
	})
}

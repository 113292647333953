import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { CommandReturnType, DeviceCommandParams } from "../client"

export function useExecuteDeviceCommandMutation(
	options?: UseMutationOptions<CommandReturnType, unknown, DeviceCommandParams>,
): UseMutationResult<CommandReturnType, unknown, DeviceCommandParams> {
	const queryClient = useQueryClient()
	const client = useClient()
	return useMutation(client.remoteCall.executeDeviceCommand, {
		...options,
		onSuccess: async () => {
			await queryClient.invalidateQueries(["deviceList"])
		},
	})
}

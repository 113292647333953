import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { Hive, IdVariable } from "../client"

export function useHiveQuery(
	variables: IdVariable,
	options?: UseQueryOptions<Hive>,
): UseQueryResult<Hive> {
	const client = useClient()
	return useQuery(
		["hive", variables],
		async () => client.hive.get(variables),
		options,
	)
}

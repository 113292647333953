import isEqual from "lodash.isequal"
import { useRef } from "react"

export function useMemoDeep<T, D>(factory: () => T, deps: D[]): T {
	const ref = useRef<{ value: T; deps: D[] }>()
	if (!ref.current || !isEqual(deps, ref.current.deps)) {
		ref.current = { value: factory(), deps }
	}
	return ref.current.value
}

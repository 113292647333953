import type { AxiosRequestConfig } from "axios"
import type { ResourceConfig } from "./common"
import type { CustomResource } from "./custom"
import { custom } from "./custom"
import type { DeviceResource } from "./device"
import { device } from "./device"
import type { HiveResource } from "./hive"
import { hive } from "./hive"
import type { HiveGroupResource } from "./hive-group"
import { hiveGroup } from "./hive-group"
import type { QuarantineResource } from "./quarantine"
import { quarantine } from "./quarantine"
import type { RemoteCallResource } from "./remoteCall"
import { remoteCall } from "./remoteCall"
import type { SessionResource } from "./session"
import { session } from "./session"

const DEFAULT_BASE_URL = "/ng/api"

export type Client = {
	device: DeviceResource
	hive: HiveResource
	hiveGroup: HiveGroupResource
	quarantine: QuarantineResource
	session: SessionResource
	remoteCall: RemoteCallResource
}

export type ClientWithCustomResource = Client & {
	custom: CustomResource
}

export function createClient(
	baseURL = DEFAULT_BASE_URL,
	configOverride?: AxiosRequestConfig,
): ClientWithCustomResource {
	const config: ResourceConfig = {
		baseURL,
		xsrfHeaderName: "X-CSRFToken",
		xsrfCookieName: "csrftoken",
		withCredentials: true,
		...configOverride,
	}
	const client = {
		device: device(config),
		hive: hive(config),
		hiveGroup: hiveGroup(config),
		quarantine: quarantine(config),
		session: session(config),
		remoteCall: remoteCall(config),
	}
	return {
		...client,
		custom: custom(client),
	}
}

import React from "react";
import styled, { css } from "styled-components";

export interface FooterProps {
  children: React.ReactNode;
}

const StyledFooter = styled.footer(
  (props) => css`
    padding: ${props.theme.spacing(2)}px;
    background-color: ${props.theme.palette.grey["100"]};
    display: flex;

    ${props.theme.breakpoints.up("sm")} {
      justify-content: center;
    }
  `,
);

const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <StyledFooter>
      <div>{children}</div>
    </StyledFooter>
  );
};

export default Footer;

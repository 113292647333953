import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import NotificationsIcon from "@material-ui/icons/Notifications";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import React from "react";

export const FLAGS = Object.freeze({
  anyatlan: {
    key: "anyatlan",
    text: "Anyátlan",
    color: "#0000FF",
    icon: ErrorIcon,
  },
  csillag: {
    key: "csillag",
    text: "Csillag",
    color: "#FDB900",
    icon: StarBorderIcon,
  },
  egyesiteni: {
    key: "egyesiteni",
    text: "Egyesíteni",
    color: "#D60F0F",
    icon: VerticalAlignCenterIcon,
  },
  pergetni: {
    key: "pergetni",
    text: "Pergetni",
    color: "#FF9F1C",
    icon: NotificationsIcon,
  },
  problema: {
    key: "problema",
    text: "Probléma",
    color: "#D60F0F",
    icon: RadioButtonCheckedIcon,
  },
});

export type FlagKey = keyof typeof FLAGS;

export interface FlagProps {
  id: FlagKey;
  tooltip?: boolean;
  disabled?: boolean;
}

export const Flag: React.FC<FlagProps> = ({ id, tooltip = false, disabled = false }) => {
  const { text, color, icon: Icon } = FLAGS[id];
  const icon = <Icon color={disabled ? "disabled" : undefined} htmlColor={color} />;
  if (tooltip) {
    return (
      <Tooltip title={text}>
        <span>{icon}</span>
      </Tooltip>
    );
  }
  return icon;
};

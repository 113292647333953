import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { SensorMeasurements24hChangesVariables } from "../client/custom"

export function useSensorMeasurements24hChangesQuery(
	variables: SensorMeasurements24hChangesVariables,
	options?: UseQueryOptions<Array<number | null>>,
): UseQueryResult<Array<number | null>> {
	const client = useClient()
	return useQuery(
		["sensorMeasurements24hChanges", variables],
		async () => client.custom.getSensorMeasurements24hChanges(variables),
		options,
	)
}

import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { LoginVariables, User } from "../client"

export function useLoginMutation(
	options?: UseMutationOptions<User, unknown, LoginVariables>,
): UseMutationResult<User, unknown, LoginVariables> {
	const client = useClient()
	const queryClient = useQueryClient()
	return useMutation(client.session.login, {
		onSuccess: (data) => {
			queryClient.setQueryData("me", data)
		},
		...options,
	})
}

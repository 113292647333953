import addHours from "date-fns/addHours"
import closestIndexTo from "date-fns/closestIndexTo"
import compareAsc from "date-fns/compareAsc"
import differenceInHours from "date-fns/differenceInHours"
import startOfYesterday from "date-fns/startOfYesterday"
import subDays from "date-fns/subDays"
import groupBy from "lodash.groupby"
import type { Client } from "./client"
import type {
	Measurement,
	Measurements,
	MeasurementValue,
	SensorMeasurementsIdVariables,
	SensorMeasurementsSeriesVariables,
} from "./device"

export type SensorMeasurements24hChangeVariables = {
	deviceId: number
	sensorKey: string
}
export type GetSensorMeasurements24hChange = (
	variables: SensorMeasurements24hChangeVariables,
) => Promise<number | null>

export type SensorMeasurements24hChangesVariables = SensorMeasurements24hChangeVariables[]
export type GetSensorMeasurements24hChanges = (
	variables: SensorMeasurements24hChangesVariables,
) => Promise<Array<number | null>>

export type SensorMeasurementsAggregationInterval = {
	id: string
	label: string
	days: number
	month: number
	deltaTimeInHours: number
}

export type SensorMeasurementsAggregationDateInterval = {
	from: Date
	to: Date
}

export type SensorMeasurementsAggregateGroup = {
	entries: Array<Measurement<number>>
	average: number
	interval: SensorMeasurementsAggregationDateInterval
}

export type SensorMeasurementsAggregate = SensorMeasurementsAggregateGroup[]

export type GetMultipleSensorsMeasurementsSeries = <
	MV extends MeasurementValue = MeasurementValue
>(
	variables: SensorMeasurementsSeriesVariables[],
) => Promise<Array<Measurements<MV>>>

export type AggregatedSensorMeasurementsVariables = SensorMeasurementsIdVariables & {
	interval: SensorMeasurementsAggregationDateInterval
	aggregateTimeInHours: number
}
export type GetAggregatedSensorMeasurements = (
	variables: AggregatedSensorMeasurementsVariables,
) => Promise<SensorMeasurementsAggregate>

export type MultipleAggregatedSensorsMeasurementsVariables = {
	interval: SensorMeasurementsAggregationDateInterval
	aggregateTimeInHours: number
	ids: SensorMeasurementsIdVariables[]
}
export type GetMultipleAggregatedSensorMeasurements = (
	variables: MultipleAggregatedSensorsMeasurementsVariables,
) => Promise<SensorMeasurementsAggregate[]>

export type CustomResource = {
	getSensorMeasurements24hChange: GetSensorMeasurements24hChange
	getSensorMeasurements24hChanges: GetSensorMeasurements24hChanges
	getMultipleSensorsMeasurementsSeries: GetMultipleSensorsMeasurementsSeries
	getAggregatedSensorMeasurements: GetAggregatedSensorMeasurements
	getMultipleAggregatedSensorMeasurements: GetMultipleAggregatedSensorMeasurements
}

function groupByIntervalFactory(
	startDate: Date,
	deltaTimeInHours: number,
): (iteratee: Measurement<number>) => string {
	return function groupByInterval({ date }: Measurement<number>): string {
		// deltaHours is the delta time from startDate
		// deltaTimeInHours is the time in one interval
		const deltaHours = differenceInHours(date, startDate)

		return addHours(
			startDate,
			deltaTimeInHours !== 0
				? Math.floor(deltaHours / deltaTimeInHours) * deltaTimeInHours
				: deltaHours,
		).toString()
	}
}

function aggregateMeasurements(
	measurements: Array<Measurement<number>>,
	startDate: Date,
	deltaTimeInHours: number,
): SensorMeasurementsAggregate {
	const grouped = groupBy(
		measurements,
		groupByIntervalFactory(startDate, deltaTimeInHours),
	)
	const sorted = Object.entries(grouped)
		.map(([dateString, group]) => ({
			dateKey: new Date(dateString),
			measurements: group,
		}))
		.sort(({ dateKey: a }, { dateKey: b }) => compareAsc(a, b))
	return sorted.reduce<SensorMeasurementsAggregate>((acc, group) => {
		const avg =
			group.measurements.reduce((s, { value }) => s + value, 0) /
			group.measurements.length
		acc.push({
			entries: group.measurements,
			average: avg,
			interval: {
				from: group.dateKey,
				to: addHours(group.dateKey, deltaTimeInHours),
			},
		})
		return acc
	}, [])
}

/**
 * Implements missing backend functionality temporarily on the client side.
 */
export function custom(client: Client): CustomResource {
	/**
	 * Returns the change between two measurements as close to a 24h window as possible if available,
	 * otherwise returns `null`.
	 * `null` is used instead of `undefined` because caching strategy can't differentiate between missing
	 * data or returned data being `undefined`.
	 *
	 * Best case scenario: _measurement right now_ minus _measurement 24 hours ago_
	 *
	 * Worst case scenario: _last available measurement between now and start of yesterday_ minus _closest available measurement to 24 hours ago_
	 */
	const getSensorMeasurements24hChange: GetSensorMeasurements24hChange = async ({
		deviceId,
		sensorKey,
	}) => {
		const now = new Date()
		const measurements = await client.device.getSensorMeasurementsSeries<
			number
		>({
			deviceId,
			sensorKey,
			from: startOfYesterday(),
			to: now,
		})
		if (measurements.length < 2) {
			return null
		}
		const { value: lastValue } = measurements[measurements.length - 1]
		const dates = measurements.map(({ date }) => date)
		const closestIndex = closestIndexTo(subDays(now, 1), dates)
		const { value: closestValue } = measurements[closestIndex]
		const value = lastValue - closestValue
		return value
	}

	const getSensorMeasurements24hChanges: GetSensorMeasurements24hChanges = async (
		variables,
	) => Promise.all(variables.map(getSensorMeasurements24hChange))

	const getMultipleSensorsMeasurementsSeries: GetMultipleSensorsMeasurementsSeries = async <
		MV extends MeasurementValue = MeasurementValue
	>(
		variables: SensorMeasurementsSeriesVariables[],
	) =>
		Promise.all(
			variables.map<Promise<Measurements<MV>>>(
				client.device.getSensorMeasurementsSeries,
			),
		)

	const getAggregatedSensorMeasurements: GetAggregatedSensorMeasurements = async (
		variables,
	) => {
		const { interval, aggregateTimeInHours, ...sensorCompositeId } = variables
		const measurements = await client.device.getSensorMeasurementsSeries<
			number
		>({ ...sensorCompositeId, ...interval })
		return aggregateMeasurements(
			measurements,
			interval.from,
			aggregateTimeInHours,
		)
	}

	const getMultipleAggregatedSensorMeasurements: GetMultipleAggregatedSensorMeasurements = async (
		variables,
	) => {
		const { interval, aggregateTimeInHours } = variables
		const res = await Promise.all(
			variables.ids.map(async (id) =>
				client.device.getSensorMeasurementsSeries<number>({
					...id,
					from: interval.from,
					to: interval.to,
				}),
			),
		)
		return res.map((measurements) =>
			aggregateMeasurements(measurements, interval.from, aggregateTimeInHours),
		)
	}

	return {
		getSensorMeasurements24hChange,
		getSensorMeasurements24hChanges,
		getMultipleSensorsMeasurementsSeries,
		getAggregatedSensorMeasurements,
		getMultipleAggregatedSensorMeasurements,
	}
}

import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { GetHiveNoteVariables, HiveNote } from "../client"

export function useHiveNoteQuery(
	variables: GetHiveNoteVariables,
	options?: UseQueryOptions<HiveNote>,
): UseQueryResult<HiveNote> {
	const client = useClient()
	return useQuery(
		["hiveNote", variables],
		async () => client.hive.getNote(variables),
		options,
	)
}

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import formatPhone from "phone";
import React from "react";
import styled from "styled-components";
import BeeBoxLogoIcon from "../BeeBoxLogoIcon";
import Footer from "../Footer";

export interface BeeBoxFooterProps {
  email: string;
  phone: string;
  version?: string;
}

const StyledGrid = styled(Grid)`
  flex-wrap: nowrap;
`;

const BeeBoxFooter: React.FC<BeeBoxFooterProps> = ({ email, phone, version }) => {
  return (
    <Footer>
      <StyledGrid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <BeeBoxLogoIcon />
        </Grid>
        <Grid item>
          {version != null && <Typography>Verzió: {version}</Typography>}
          <Typography>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Typography>
          <Typography>
            <Link href={`tel:${formatPhone(phone)[0]}`}>{phone}</Link>
          </Typography>
        </Grid>
      </StyledGrid>
    </Footer>
  );
};

export default BeeBoxFooter;

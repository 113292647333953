import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type {
	AggregatedSensorMeasurementsVariables,
	SensorMeasurementsAggregate,
} from "../client/custom"

export function useAggregatedSensorMeasurements(
	variables: AggregatedSensorMeasurementsVariables,
	options?: UseQueryOptions<SensorMeasurementsAggregate>,
): UseQueryResult<SensorMeasurementsAggregate> {
	const client = useClient()
	return useQuery(
		["aggregatedSensorMeasurements", variables],
		async () => client.custom.getAggregatedSensorMeasurements(variables),
		options,
	)
}

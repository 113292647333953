import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import React from "react";

export interface BackIconButtonProps extends IconButtonProps {
  onClick: () => void;
}

const BackIconButton: React.FC<BackIconButtonProps> = ({ onClick, ...props }) => {
  return (
    <IconButton {...props} aria-label="back" onClick={onClick}>
      <BackIcon />
    </IconButton>
  );
};

export default BackIconButton;

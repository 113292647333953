import Axios from "axios"
import urlcat from "urlcat"
import { objectToFormData } from "../utils/objectToFormData"
import type { EmptyObject, ResourceConfig } from "./common"

export type User = {
	id: number
	name: string
	email: string
	address?: string
	phone_number_primary?: string
	billing_address?: string
	is_subscribed?: boolean
	is_gdpr_approved?: boolean
}

export type LoginVariables = {
	username: string
	password: string
}

export type GetMe = () => Promise<User>

export type Login = (variables: LoginVariables) => Promise<User>

export type Logout = () => Promise<EmptyObject>

export type SessionResource = {
	getMe: GetMe
	login: Login
	logout: Logout
}

/**
 * Session
 *
 * https://bee-box.io/ng/docs/rest/index.html#api-session
 */
export function session(defaultConfig: ResourceConfig): SessionResource {
	const axios = Axios.create({
		...defaultConfig,
		baseURL: urlcat(defaultConfig.baseURL, "session/"),
	})

	/** https://bee-box.io/ng/docs/rest/index.html#get--api-session- */
	const getMe: GetMe = async () => (await axios.get<User>("")).data

	/** https://bee-box.io/ng/docs/rest/index.html#post--api-session- */
	const login: Login = async (variables) => {
		return (await axios.post<User>("", objectToFormData(variables))).data
	}

	/** https://bee-box.io/ng/docs/rest/index.html#post--api-session-delete- */
	const logout: Logout = async () =>
		(await axios.post<EmptyObject>("delete/")).data

	return {
		getMe,
		login,
		logout,
	}
}

import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { DeleteHiveNoteVariables, HiveNoteIdVariables } from "../client"

export function useDeleteHiveNoteMutation(
	options?: UseMutationOptions<
		HiveNoteIdVariables,
		unknown,
		DeleteHiveNoteVariables
	>,
): UseMutationResult<HiveNoteIdVariables, unknown, DeleteHiveNoteVariables> {
	const queryClient = useQueryClient()
	const client = useClient()
	return useMutation(client.hive.deleteNote, {
		...options,
		onSuccess: async (data, variables) => {
			const { hiveId } = variables
			await queryClient.invalidateQueries(["hiveNotes", { hiveId }])
		},
	})
}

import { Grid, IconButton, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import styled, { css } from "styled-components";
import { formatDateTime } from "../../util";
import { Flag, FlagKey, FLAGS } from "../Flag";

export interface HiveNoteProps {
  className?: string;
  date: Date;
  text?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  flag?: FlagKey;
  imgSrc?: string;
}

const StyledHeaderGrid = styled(Grid)(
  ({ theme: { breakpoints } }) => css`
    justify-content: space-between;
    align-items: center;

    ${breakpoints.up("sm")} {
      justify-content: flex-start;
    }
  `,
);

const StyledPaper = styled(Paper)(
  (props) => css`
    padding: ${props.theme.spacing(1)}px;
  `,
);

const StyledImageContainer = styled(Grid)`
  /* width: 128px;
  height: 128px; */
`;

const StyledImage = styled.img`
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 320px;
`;

function HiveNote({
  className,
  flag,
  text,
  date,
  imgSrc,
  onEdit,
  onDelete,
}: HiveNoteProps): React.ReactElement {
  return (
    <StyledPaper className={className} elevation={0} square>
      <StyledHeaderGrid container spacing={2}>
        <Grid item>
          <Typography>{formatDateTime(date)}</Typography>
        </Grid>
        {flag != null && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Flag id={flag} />
              </Grid>
              <Grid item>
                <Typography>{FLAGS[flag].text}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {onEdit && (
          <Grid item>
            <IconButton onClick={onEdit}>
              <Edit fontSize="inherit" color="primary" />
            </IconButton>
          </Grid>
        )}
        {onDelete && (
          <Grid item>
            <IconButton onClick={onDelete}>
              <Delete fontSize="inherit" color="primary" />
            </IconButton>
          </Grid>
        )}
      </StyledHeaderGrid>
      {imgSrc != null ? (
        <Grid container direction="row" spacing={1}>
          <StyledImageContainer item xs={12} sm={4} md={2}>
            <a href={imgSrc} target="_blank" rel="noreferrer">
              <StyledImage src={imgSrc} alt={text} />
            </a>
          </StyledImageContainer>
          <Grid item xs={12} sm={8} md={10}>
            <Typography>{text}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography>{text}</Typography>
      )}
    </StyledPaper>
  );
}

export default HiveNote;

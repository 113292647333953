import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { GetHiveNotesVariables, HiveNote } from "../client"

export function useHiveNotesQuery(
	variables: GetHiveNotesVariables,
	options?: UseQueryOptions<HiveNote[]>,
): UseQueryResult<HiveNote[]> {
	const client = useClient()
	return useQuery(
		["hiveNotes", variables],
		async () => client.hive.getNotes(variables),
		options,
	)
}

import type { UseMutationOptions, UseMutationResult } from "react-query"
import { useMutation, useQueryClient } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { HiveNote, UpdateHiveNoteVariables } from "../client"

export function useUpdateHiveNoteMutation(
	options?: UseMutationOptions<HiveNote, unknown, UpdateHiveNoteVariables>,
): UseMutationResult<HiveNote, unknown, UpdateHiveNoteVariables> {
	const queryClient = useQueryClient()
	const client = useClient()
	return useMutation(client.hive.updateNote, {
		...options,
		onSuccess: async (data, variables) => {
			const { hiveId, noteId } = variables
			await queryClient.invalidateQueries(["hiveNote", { hiveId, noteId }])
			await queryClient.invalidateQueries(["hiveNotes", { hiveId }])
		},
	})
}

import type { AxiosRequestConfig } from "axios"
import React, { createContext, useContext } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import type { ClientWithCustomResource } from "./client"
import { createClient } from "./client/client"
import { useMemoDeep } from "./utils/useMemoDeep"

const defaultQueryClient = new QueryClient()

const BeeBoxApiClientContext = createContext<
	ClientWithCustomResource | undefined
>(undefined)

export type BeeBoxApiClientProviderProps = {
	baseUrl?: string
	config?: AxiosRequestConfig
	queryClient?: QueryClient
}

export const BeeBoxApiClientProvider: React.FC<BeeBoxApiClientProviderProps> = ({
	baseUrl,
	queryClient,
	children,
	config,
}) => {
	const client = useMemoDeep(() => createClient(baseUrl, config), [
		baseUrl,
		config,
	])
	return (
		<QueryClientProvider client={queryClient ?? defaultQueryClient}>
			<BeeBoxApiClientContext.Provider value={client}>
				{children}
			</BeeBoxApiClientContext.Provider>
		</QueryClientProvider>
	)
}

export function useClient(): ClientWithCustomResource {
	const client = useContext(BeeBoxApiClientContext)
	if (!client) {
		throw new Error("BeeBoxApiClientProvider is missing")
	}
	return client
}

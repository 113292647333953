import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { Device, Page, PaginationVariables } from "../client"

export function useDeviceListQuery(
	variables: PaginationVariables,
	options?: UseQueryOptions<Page<Device>>,
): UseQueryResult<Page<Device>> {
	const client = useClient()
	return useQuery(
		["deviceList", variables],
		async () => client.device.list(variables),
		options,
	)
}

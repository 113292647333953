import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import { useClient } from "../BeeBoxApiClientContext"
import type { IdVariable, Quarantine } from "../client"

export function useQuarantineQuery(
	variables: IdVariable,
	options?: UseQueryOptions<Quarantine>,
): UseQueryResult<Quarantine> {
	const client = useClient()
	return useQuery(
		["quarantine", variables],
		async () => client.quarantine.get(variables),
		options,
	)
}

import React from "react"
import { useMeQuery } from "./hooks"

export type RememberMeProps = {
	fallback?: React.ReactNode
}

export const RememberMe: React.FC<RememberMeProps> = ({
	children,
	fallback,
}) => {
	const { isLoading } = useMeQuery({
		retry: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	})
	if (isLoading) {
		return <>{fallback}</>
	}
	return <>{children}</>
}

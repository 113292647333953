import type { UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"
import type { User } from ".."
import { useClient } from "../BeeBoxApiClientContext"

export function useMeQuery(
	options?: UseQueryOptions<User>,
): UseQueryResult<User> {
	const client = useClient()
	return useQuery("me", client.session.getMe, options)
}

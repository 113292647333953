import React from "react";
import styled from "styled-components";

export interface DebugProps {
  value: any;
  light?: boolean;
}

const StyledPre = styled.pre<{ light: boolean }>`
  padding: 8px;
  background-color: ${(props) => (props.light ? "#eee8d5" : "#282c34")};
  color: ${(props) => (props.light ? "#586e75" : "#abb2bf")};
`;

/**
 * `Debug` is used to render any value as a formatted JSON string.
 * Console logging can be cumbersome and redundant during the component lifecycle,
 * so this component offers a simple way to render data on the page.
 */
const Debug: React.FC<DebugProps> = ({ value, light = false }) => {
  return (
    <StyledPre light={light}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
      {JSON.stringify(value, (_, v) => (typeof v === "undefined" ? "undefined" : v), 2)}
    </StyledPre>
  );
};

export default Debug;
